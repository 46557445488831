<template>
  <div>
    <div ref="clink" style="display: none"></div>
    <!-- 最上边 -->
    <div class="center-top" v-if="isListion">
      <!--<div class="top-name">{{ this.cno }}</div>
      <div class="lisitions">{{ this.Personstatus }}</div>-->
      <div class="top-tome">
        <!--<div class="timer">
          <div class="time-card" data-type="hours" data-max="24">
            <div class="time-card-count">{{ myHours }}</div>
          </div>
          <span class="colon">:</span>
          <div class="time-card" data-type="minutes" data-max="60">
            <div class="time-card-count">{{ myMinutes }}</div>
          </div>
          <span class="colon">:</span>
          <div class="time-card" data-type="seconds" data-max="60">
            <div class="time-card-count">{{ mySeconds }}</div>
          </div>
        </div>-->
      </div>
      <div class="top-tu">
        <div class="top-tu-one">
          <img
            src="../../assets/images/top03.jpg"
            alt=""
            @click="callBackMessage"
            v-if="imgIcon"
          />
          <el-button
            v-if="imgIcons"
            :disabled="true"
            style="border: none; color: rgb(152, 140, 140); padding: 0px"
          >
            <img src="../../assets/images/top03.jpg" />
          </el-button>
        </div>
        <!--<div class="top-tu-one">
          <img src="../../assets/images/top05.jpg" alt="" v-if="isAnswer" />
        </div>
        <div class="top-tu-one">
          <img src="../../assets/images/top07.jpg" alt="" v-if="isAnswer" />
        </div>-->
      </div>
      <div class="top-rig">
        <div class="top-right">
          <span v-if="isFour" class="fourCall">400呼叫</span>
          <span v-if="isServer" class="fourCall">客服直呼</span>
          <span v-if="isServer1" class="fourCall">客服回拨</span>
          <img
            :class="[{ r1: shrinkPacket }, { r2: !shrinkPacket }]"
            src="../../assets/images/ling03.jpg"
            alt=""
          />
        </div>
        <!-- <div class="top-time">00:25</div> -->
        <div class="top-jie">
          <img
            src="../../assets/images/ling05.jpg"
            alt=""
            @click="listion"
            v-if="isAnswer"
          />
        </div>
        <div class="top-zhuangtai">
          <span class="my-zhuangtai">我的状态</span>
          <el-select
            v-model="valuezhuangtai"
            placeholder="请选择"
            style="width: 100px; height: 30px"
            @change="myStatus"
          >
            <el-option
              v-for="item in optionszhuangtai"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @click.native="myStatusValue(item.value)"
            >
            </el-option>
          </el-select>
          <div class="top-tonghua" v-if="isArrangement">
            <div class="timer">
              <div class="time-card" data-type="hours" data-max="24">
                <div class="time-card-count">{{ myHours2 }}</div>
              </div>
              <span class="colon">:</span>
              <div class="time-card" data-type="minutes" data-max="60">
                <div class="time-card-count">{{ myMinutes2 }}</div>
              </div>
              <span class="colon">:</span>
              <div class="time-card" data-type="seconds" data-max="60">
                <div class="time-card-count">{{ mySeconds2 }}</div>
              </div>
            </div>
          </div>
          <div class="top-tonghua" v-if="isArrangement1">
            请稍后，正在整理中...
          </div>
        </div>
      </div>
    </div>
    <!-- 最上边接听之后的 -->
    <div class="center-top" v-if="isListioning">
      <div class="top-name">
        {{ this.xianName
        }}<span style="margin-left: 10px; font-size: 16px">{{
          xianPhone
        }}</span>
      </div>
      <div class="lisitions">接听中</div>
      <div class="top-tome">
        <div class="timer">
          <div class="time-card" data-type="hours" data-max="24">
            <div class="time-card-count">{{ myHours1 }}</div>
          </div>
          <span class="colon">:</span>
          <div class="time-card" data-type="minutes" data-max="60">
            <div class="time-card-count">{{ myMinutes1 }}</div>
          </div>
          <span class="colon">:</span>
          <div class="time-card" data-type="seconds" data-max="60">
            <div class="time-card-count">{{ mySeconds1 }}</div>
          </div>
        </div>
      </div>
      <div class="top-tu">
        <div class="top-tu-one">
          <img
            src="../../assets/images/images/jingyin_03.jpg"
            alt=""
            @click="jinyin"
            v-if="isYIN"
            style="margin-left: 10px"
          />
          <img
            src="../../assets/取消静音.png"
            alt=""
            @click="jinyin"
            v-if="isJIN"
            style="margin-left: 10px"
          />
        </div>
        <!-- <div class="top-tu-one">
          <img src="../../assets/images/images/zhuanjie_05.jpg" alt="" />
        </div>
        <div class="top-tu-one">
          <img
            src="../../assets/images/images/yiliao_07.jpg"
            alt=""
            v-if="iconImg"
          />
        </div>
        <div class="top-tu-one">
          <img
            src="../../assets/images/images/jijiu_09.jpg"
            alt=""
            v-if="iconImgs"
          />
        </div>
        <div class="top-tu-one">
          <img
            src="../../assets/images/images/jinji_11.jpg"
            alt=""
            v-if="iconImgs"
          />
        </div> -->
        <div class="top-tu-one">
          <img
            src="../../assets/images/images/guaduan_13.jpg"
            alt=""
            @click="offPhone"
          />
        </div>
      </div>
      <div class="top-zhuangtai1">
        <span class="my-zhuangtai">我的状态</span>
        <el-select
          v-model="valuezhuangtai"
          placeholder="请选择"
          style="width: 100px; height: 30px"
          :disabled="true"
        >
          <el-option
            v-for="item in optionszhuangtai"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            @click.native="myStatusValue(item.value)"
          >
          </el-option>
        </el-select>
        <div class="top-tonghua">
          <div class="timer">
            <div class="time-card" data-type="hours" data-max="24">
              <div class="time-card-count">{{ myHours2 }}</div>
            </div>
            <span class="colon">:</span>
            <div class="time-card" data-type="minutes" data-max="60">
              <div class="time-card-count">{{ myMinutes2 }}</div>
            </div>
            <span class="colon">:</span>
            <div class="time-card" data-type="seconds" data-max="60">
              <div class="time-card-count">{{ mySeconds2 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 10px" v-if="isDiv"></div>
    <!-- 下边 -->
    <div class="bottom">
      <!-- 下左边 -->
      <div class="bottom-left">
        <div :class="[cusClass == 1 ? 'badge' : 'badges']">
          {{ this.count }}
        </div>
        <div
          :class="[cusClass == 1 ? 'badge1' : 'badge1s']"
          v-if="isShowCommission"
        >
          {{ this.countAll }}
        </div>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="常用知识" name="first">
            <div class="search">
              <el-input
                v-model="searchs"
                @input="handlChange(searchs)"
                placeholder="请输入搜索内容"
                style="width: 180px; margin-top: 10px"
              ></el-input>
              <!--<img
                class="imgs"
                src="../../assets/images/query.png"
                @click="reset()"
                alt
              />-->
            </div>
            <div class="left">
              <div class="trees">
                <el-container style="height: 500px">
                  <el-aside width="200px" class="aside-tree">
                    <el-tree
                      :data="dataList"
                      :props="defaultProps"
                      @node-click="handleNodeClick"
                      :filter-node-method="filterNode"
                      ref="tree"
                    >
                    </el-tree>
                  </el-aside>
                </el-container>
              </div>
              <div class="right">
                <p v-text="dataTitle"></p>
                <p v-html="dataLists"></p>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="待我办理" name="second">
            <div>
              <el-table
                :data="tableData"
                style="width: 100%"
                height="730"
                :default-sort="{ prop: 'createDate', order: 'descending' }"
                @row-click="rowClick"
              >
                <el-table-column prop="workNumber" label="工单编号" width="100">
                </el-table-column>
                <el-table-column prop="newPhone" label="通话对象" width="100">
                </el-table-column>
                <el-table-column
                  prop="equipmentNo"
                  label="设备号码"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="remainingTime"
                  label="剩余时间"
                  width="120"
                  sortable
                >
                </el-table-column>
                <el-table-column
                  prop="priority"
                  label="优先级"
                  width="100"
                  sortable
                >
                </el-table-column>
                <el-table-column prop="createDate" label="添加时间" width="180">
                </el-table-column>
                <el-table-column label="处理">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleEditDeal(scope.$index, scope.row)"
                      >完成</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="通话记录" name="five">
            <div class="tonghua">
              <!-- <i class="iconfont icon-triangle-left"></i> -->
              <el-date-picker
                v-model="timeDateShow"
                type="date"
                placeholder="选择日期"
                style="margin-right: 13px; margin-top: 3px"
                @change="timeDate(timeDateShow)"
              >
              </el-date-picker>
              <!-- <i class="iconfont icon-triangle-right"></i> -->
              当日累计通话：{{ this.totalTime1 }}
            </div>
            <div>
              <el-table
                :data="tableData1"
                ref="tableAudio"
                height="700"
                style="width: 100%"
                @row-click="rowBtn"
              >
                <el-table-column
                  prop="NewcustomerNumber"
                  label="通话对象"
                  width="150"
                >
                </el-table-column>
                <el-table-column prop="callMode" label="通话方式" width="130">
                </el-table-column>
                <el-table-column prop="startTime" label="开始时间" width="110">
                </el-table-column>
                <el-table-column prop="bridgeDuration" label="通话时长">
                </el-table-column>
                <el-table-column prop="qiniuUrl" label="回放录音" width="250">
                  <template slot-scope="scope">
                    <audio
                      @play="handlePlay(scope.$index)"
                      name="audioOne"
                      controls
                      controlslist="nodownload"
                      :src="scope.row.qiniuUrl"
                      @input="$forceUpdate()"
                      @change="$forceUpdate()"
                      type="audio/mp3"
                    ></audio>
                  </template>

                  <div v-show="!isBroadcast">
                    <i class="iconfont el-icon-video-pause luyin"></i>
                  </div>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="全部待办" name="six" v-if="isShowCommission">
            <div>
              <el-table
                :data="tableData2"
                style="width: 100%"
                height="730"
                @row-click="rowClick"
              >
                <el-table-column prop="workNumber" label="工单编号" width="90">
                </el-table-column>
                <el-table-column prop="newPhone" label="通话对象" width="100">
                </el-table-column>
                <el-table-column
                  prop="equipmentNo"
                  label="设备号码"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="remainingTime"
                  label="剩余时间"
                  width="90"
                >
                </el-table-column>
                <el-table-column prop="priority" label="优先级" width="80">
                </el-table-column>
                <el-table-column prop="createDate" label="添加时间" width="180">
                </el-table-column>
                <el-table-column prop="userId" label="关联客服" width="100">
                </el-table-column>
                <el-table-column label="处理">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >完成</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column label="改派">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleEditChange(scope.$index, scope.row)"
                      >改派</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 下右边 -->
      <div class="bottom-right">
        <el-tabs
          v-model="activeName1"
          type="card"
          @tab-click="handleClickRight"
        >
          <el-tab-pane label="工单管理" name="first">
            <div class="history">
              <div class="historydan">历史工单</div>
              <ul class="history-ul">
                <li
                  v-for="(item, index) in historyList"
                  :key="index"
                  :class="isactive == index ? 'addclass' : ''"
                  @click="onclick(index)"
                >
                  <div class="suggest">{{ item.businessType }}</div>
                  <span class="times">{{ item.createDate }}</span
                  ><span class="person">客服</span
                  ><span class="times">{{ item.userName }}</span>
                  <span class="person-id">ID</span
                  ><span class="times">{{ item.workNumber }}</span>
                  <div class="content">{{ item.describe }}</div>
                </li>
              </ul>
            </div>
            <div class="create-order">
              <div class="historydan1">创建工单</div>
              <!-- <span>
                <el-select
                  v-model="valueOrder"
                  placeholder="请选择"
                  style="width: 120px"
                >
                  <el-option
                    v-for="item in historyOrder"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </span> -->
              <div class="infor-content">
                <el-form :model="workOrder" style="display: flex">
                  <div class="inforwrite">
                    <el-input
                      placeholder="请输入姓名"
                      v-model="workOrder.customerName"
                      style="width: 118px; margin: 10px 10px 0px 10px"
                      @input="$forceUpdate()"
                      @change="$forceUpdate()"
                    ></el-input>
                    <el-select v-model="workOrder.sex" style="width: 90px">
                      <el-option
                        v-for="item in historyOrdersex"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-input
                      style="width: 118px; margin-left: 3.4%"
                      placeholder="请输入手机号"
                      v-model="workOrder.fixedPhone"
                      :disabled="true"
                    >
                    </el-input
                    ><br />
                    <el-select
                      v-model="workOrder.province"
                      style="width: 110px; margin: 10px 10px 0px 10px"
                      placeholder="选择省份"
                      @change="provinces(workOrder.province)"
                    >
                      <el-option
                        v-for="item in historyProvince"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-model="workOrder.city"
                      @change="citysName(workOrder.city)"
                      style="width: 110px"
                      placeholder="选择城市"
                    >
                      <el-option
                        v-for="item in historyOrderCity"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <div class="fenglei">业务分类</div>
                    <div class="radio-dan">
                      <el-radio-group v-model="workOrder.businessType">
                        <el-radio label="1">售后服务</el-radio>
                        <el-radio label="2">商务合作</el-radio>
                        <el-radio label="3">投诉建议</el-radio>
                      </el-radio-group>
                    </div>
                  </div>
                  <div class="his-right">
                    <el-input
                      type="textarea"
                      :rows="5"
                      placeholder="请输入内容"
                      v-model="workOrder.describe"
                      maxlength="100"
                      show-word-limit
                    >
                    </el-input>
                    <div class="infor-bottom">
                      <div>
                        <el-checkbox
                          true-label="1"
                          false-label="0"
                          v-model="workOrder.callback"
                          @change="callBackPhones(workOrder.callback)"
                          >需要回电</el-checkbox
                        >
                      </div>
                      <span
                        v-if="CallbackInformation"
                        style="margin-right: 5px"
                      >
                        <el-select
                          v-model="workOrder.handleTime"
                          style="width: 90px"
                        >
                          <el-option
                            v-for="item in historyOrdertime"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </span>
                      <span v-if="CallbackInformation">
                        <el-select
                          v-model="workOrder.priority"
                          style="width: 90px"
                        >
                          <el-option
                            v-for="item in historyOrderputong"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </span>
                      <div>
                        <span v-if="CallbackInformation">
                          <el-checkbox
                            true-label="1"
                            false-label="0"
                            v-model="callbackNum"
                            checked="checked"
                            @change="Callbacks(callbackNum)"
                            >本机回电</el-checkbox
                          >
                        </span>
                        <el-input
                          v-if="CallbackInformation"
                          v-show="showPhone"
                          class="benji-phone"
                          style="width: 118px; font-size: 14px"
                          :disabled="workOrders"
                          v-model="workOrder.callBackPhoneOne"
                          @input="$forceUpdate()"
                          @change="$forceUpdate()"
                          placeholder="请输入手机号"
                        ></el-input>
                        <el-input
                          v-if="CallbackInformation"
                          class="benji-phone"
                          v-show="showPhones"
                          style="width: 118px; font-size: 14px"
                          v-model="workOrder.callBackPhone"
                          @input="$forceUpdate()"
                          @change="callBackOne()"
                          placeholder="请输入手机号"
                        ></el-input>
                        <!-- <span class="benji-phone" :model="workOrder.workNumber"
                          >12639875633</span
                        > -->
                        <el-button
                          type="primary"
                          @click="finish"
                          class="buttons"
                          >提交</el-button
                        >
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="信息查询" name="second">
            <div class="right-big">
              <div class="infor-top">
                <div class="baseinfor">基础信息</div>
                <div class="base-niceng">
                  <el-select
                    v-model="valuebase"
                    @change="valuebases(valuebase)"
                    placeholder="请选择用户昵称"
                    style="width: 150px; height: 20px"
                  >
                    <el-option
                      v-for="item in optionsbase"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="base-imgs">
                  <el-input
                    v-model="inputsize"
                    placeholder="请输入关键字"
                    style="width: 150px; margin-left: 20px"
                  ></el-input>
                </div>

                <el-popover
                  placement="bottom"
                  width="665"
                  trigger="click"
                  ref="taPopoverOne"
                >
                  <el-table
                    :data="gridData"
                    @row-click="rowClicks"
                    max-height="380"
                  >
                    <el-table-column
                      width="150"
                      property="equipmentNo"
                    ></el-table-column>
                    <el-table-column
                      width="100"
                      property="name"
                    ></el-table-column>
                    <el-table-column
                      width="130"
                      property="sparePhone"
                    ></el-table-column>
                    <el-table-column
                      width="130"
                      property="emergencyContact"
                    ></el-table-column>
                    <el-table-column
                      width="130"
                      property="emergencyContactPhone"
                    ></el-table-column>
                  </el-table>
                  <img
                    @click="searchList"
                    class="base-images"
                    src="../../assets/images/query.png"
                    alt
                    slot="reference"
                  />
                </el-popover>
              </div>
              <!-- 基本信息 -->
              <div class="base-content">
                <el-form
                  :model="formMember"
                  :inline="true"
                  :label-position="labelPosition"
                >
                  <el-col :span="10">
                    <el-form-item label="姓 名">
                      <el-input
                        v-model="formMember.name"
                        autocomplete="off"
                        style="width: 140px; margin-left: 11px"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="备用号码">
                      <el-input
                        v-model="formMember.sparePhones"
                        autocomplete="off"
                        style="width: 140px; margin-left: 15px"
                        @input="$forceUpdate()"
                        @change="sphonesOne(formMember.sparePhones)"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="家庭ID" class="faimal">
                      <el-input
                        v-model="formMember.familyId"
                        autocomplete="off"
                        style="width: 125px; margin-left: 15px"
                        :disabled="true"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="14">
                    <el-form-item label="紧急联系人">
                      <el-select
                        style="width: 100px"
                        v-model="formMember.emergencyContactOne"
                        @change="
                          emergencyContact(formMember.emergencyContactOne)
                        "
                      >
                        <el-option
                          v-for="item in ursents"
                          :key="item.userName"
                          :label="item.userName"
                          :value="item.userName"
                        ></el-option>
                      </el-select>
                      <el-input
                        v-model="formMember.emergencyContactOnePhone"
                        :disabled="true"
                        autocomplete="off"
                        style="width: 140px"
                        @input="$forceUpdate()"
                        @change="$forceUpdate()"
                      ></el-input
                      ><br />
                      <el-select
                        style="width: 100px"
                        v-model="formMember.emergencyContactTwo"
                        @change="
                          emergencyContacts(formMember.emergencyContactTwo)
                        "
                      >
                        <el-option
                          v-for="item in ursentsTwo"
                          :key="item.userName"
                          :label="item.userName"
                          :value="item.userName"
                        >
                        </el-option>
                      </el-select>
                      <el-input
                        v-model="formMember.emergencyContactTwoPhone"
                        :disabled="true"
                        autocomplete="off"
                        style="width: 140px"
                        @input="$forceUpdate()"
                        @change="$forceUpdate()"
                      ></el-input>
                      <!--<el-input
                        style="width: 60px"
                        v-model="formMember.emergencyContactThree"
                      ></el-input>
                      <el-input
                        v-model="formMember.emergencyContactThreePhone"
                        autocomplete="off"
                        style="width: 140px"
                      ></el-input>-->
                    </el-form-item> </el-col
                  ><br />
                  <el-col :span="10">
                    <el-form-item label="性 别">
                      <el-select
                        v-model="formMember.sex"
                        placeholder="请选择"
                        style="width: 140px; margin-left: 11px"
                      >
                        <el-option
                          v-for="item in optionssex"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" class="inforbirth">
                    <el-form-item label="生 日">
                      <el-date-picker
                        v-model="formMember.birthday"
                        type="date"
                        placeholder="选择日期"
                        style="width: 140px; margin-left: 11px"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-form-item label="登记位置" class="dengji">
                    <el-select
                      v-model="formMember.province"
                      style="width: 90px"
                      @change="provinceOne(formMember.province)"
                    >
                      <el-option
                        v-for="item in historyProvinces"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-model="formMember.city"
                      style="width: 90px"
                      @change="provinceTwo(formMember.city)"
                    >
                      <el-option
                        v-for="item in historyOrderCitys"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-model="formMember.county"
                      @change="Fcounty(formMember.county)"
                      style="width: 90px"
                    >
                      <el-option
                        v-for="item in historycounty"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <!-- <el-select v-model="value2" style="width: 90px">
                      <el-option
                        v-for="item in options2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-select v-model="value3" style="width: 90px">
                      <el-option
                        v-for="item in options2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-select v-model="value4" style="width: 90px">
                      <el-option
                        v-for="item in options2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select> -->
                  </el-form-item>
                  <el-input
                    class="inforInput"
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="formMember.address"
                  >
                  </el-input>
                  <el-col :span="16" class="shiji">
                    <el-form-item label="实际位置">
                      <div class="shijibox">
                        {{ formMember.address }}
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" class="jihuo">
                    <el-form-item label="激活日期">
                      <el-input
                        v-model="formMember.joinDate"
                        autocomplete="off"
                        style="width: 110px"
                        :disabled="true"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" class="daoqi">
                    <el-form-item label="到期日期">
                      <el-input
                        v-model="formMember.leaveDate"
                        autocomplete="off"
                        style="width: 110px"
                        :disabled="true"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-form>
              </div>
              <div class="big-center">
                <!-- 生活状况 -->
                <div class="life-status">
                  <div class="life-zhuangtai">生活状况</div>
                  <table>
                    <tr>
                      <td>居住情况</td>
                      <td>
                        <el-select
                          v-model="formMemberInfo.live"
                          style="width: 100px"
                        >
                          <el-option
                            v-for="item in live"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </td>
                    </tr>
                    <tr>
                      <td>智力状态</td>
                      <td>
                        <el-select
                          v-model="formMemberInfo.intellect"
                          style="width: 100px"
                        >
                          <el-option
                            v-for="item in zhiLi"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </td>
                    </tr>
                    <tr>
                      <td>性格特征</td>
                      <td>
                        <el-select
                          v-model="formMemberInfo.character"
                          style="width: 100px"
                        >
                          <el-option
                            v-for="item in xingge"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </td>
                    </tr>
                    <tr>
                      <td>身体状态</td>
                      <td>
                        <div class="tdspan">
                          <span
                            >语言：
                            <el-select
                              v-model="formMemberInfo.language"
                              style="width: 100px"
                            >
                              <el-option
                                v-for="item in yuyan"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </span>
                        </div>
                        <div class="tdspan">
                          <span
                            >听力：
                            <el-select
                              v-model="formMemberInfo.hearing"
                              style="width: 100px"
                            >
                              <el-option
                                v-for="item in tingli"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </span>
                        </div>
                        <div class="tdspan">
                          <span
                            >视力：
                            <el-select
                              v-model="formMemberInfo.vision"
                              style="width: 100px"
                            >
                              <el-option
                                v-for="item in shili"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </span>
                        </div>
                        <div class="tdspan">
                          <span>
                            行动：
                            <el-select
                              v-model="formMemberInfo.action"
                              style="width: 100px"
                            >
                              <el-option
                                v-for="item in xingdong"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>慢性病史</td>
                      <td>
                        <div
                          class="healLabel"
                          v-html="formMemberInfo.illness"
                        ></div>
                        <el-input
                          v-model="inputman"
                          placeholder="请输入慢性病"
                          v-if="isZhan"
                          @change="zhanChange"
                        ></el-input>
                      </td>
                    </tr>
                    <tr>
                      <td class="infor-img">
                        <img
                          src="../../assets/images/add.png"
                          alt=""
                          @click="addInput"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
                <!-- 健康数据 -->
                <div class="jiangkang">
                  <div class="life-zhuangtai">健康数据</div>
                  <table>
                    <tr>
                      <td>血压</td>
                      <td>{{ forBody.bloodPressure }}</td>
                    </tr>
                    <tr>
                      <td>血糖</td>
                      <td>
                        <div class="tdspan">
                          <span>{{ forBody.bloodSugar }}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>血氧</td>
                      <td>{{ forBody.oxygen }}</td>
                    </tr>
                    <tr>
                      <td>心率</td>
                      <td>{{ forBody.heartRate }}</td>
                    </tr>
                    <tr>
                      <td>体温</td>
                      <td>{{ forBody.temperature }}</td>
                    </tr>
                    <tr>
                      <td>睡眠</td>
                      <td>{{ forBody.sleep }}</td>
                    </tr>
                    <tr>
                      <td>用药安排</td>
                      <!-- <td>
                        <div class="tdspan">
                          <span
                            >{{ morningbefor.medicationDate }}
                            {{ morningbefor.drugCode }}
                            {{ morningbefor.unit }}</span
                          >
                        </div>
                        <div class="tdspan">
                          <span
                            >{{ morningafter.medicationDate }}
                            {{ lunchbefor.unit }}</span
                          >
                        </div>
                        <div class="tdspan">
                          <span
                            >{{ lunchafter.medicationDate }}
                            {{ lunchafter.drugCode }}
                            {{ lunchafter.unit }}</span
                          >
                        </div>
                        <div class="tdspan">
                          <span
                            >{{ eveningbefor.medicationDate }}
                            {{ eveningbefor.drugCode }}
                            {{ eveningbefor.unit }}</span
                          >
                        </div>
                        <div class="tdspan">
                          <span>晚餐后 xxxx 1颗</span>
                        </div>
                      </td> -->
                    </tr>
                  </table>
                  <span class="buttons baocun" @click="preservation">保存</span>
                  <!-- <span class="buttons baocun">保存</span> -->
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="远程协助" name="third" v-if="isHelp"
            >远程协助</el-tab-pane
          > -->
          <el-tab-pane label="生活服务" name="fourth" v-if="isLive"
            >生活服务</el-tab-pane
          >
        </el-tabs>
      </div>
    </div>

    <!--<audio ref="audio" src="" controls="controls"></audio>-->
    <audio ref="audioLing" loop src="../../assets/6027.mp3" muted></audio>

    <!-- 点击改派的弹框 -->
    <el-dialog :visible.sync="dialogVisible1" width="20%">
      <el-table
        :data="tableDataPerson"
        style="width: 100%"
        @cell-click="cellClick1"
      >
        <el-table-column prop="name" label="关联客服" width="180">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="quietChange">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 点击待我办理中的完成时的弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleFinish"
      width="30%"
      :before-close="handleCloseFinish"
    >
      <span>确定要完成该工单吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleFinish = false">取 消</el-button>
        <el-button type="primary" @click="quitFinish">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="tellStatus"
      :visible.sync="dialogVisibleTell"
      width="40%"
      class="tellLink"
      :show-close="false"
      :before-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      电话号码：<el-input
        v-model="inputPhsTell"
        placeholder="请输入电话"
        style="width: 300px"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelTell(inputPhsTell)" v-if="closeButton"
          >取消转接</el-button
        >
        <el-button @click="closeTell()" v-if="!closeButton">关 闭</el-button>
        <el-button
          type="primary"
          @click="tellQuit(inputPhsTell)"
          v-if="!closeButton"
          >确认转接</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
import { CodeToText } from "element-china-area-data";
export default {
  inject: ["reload"],
  props: ["twoType", "serStatusee", "seHere"],
  data() {
    return {
      //转接号码
      dialogVisibleTell: false,
      tellStatus: "手机转接",
      inputPhsTell: "",
      closeButton: false,
      messageCode: null,
      messageCodes: null,
      isArrangement: true,
      isArrangement1: false,
      seType: "",
      isFour: false,
      isServer: false,
      isServer1: false,
      isYIN: true,
      isJIN: false,
      isactive: false,
      audioElement: [], // 创建一个数组
      dialogVisible1: false,
      dialogVisibleFinish: false, //待我办理的完成
      callbackNum: false,
      timeDateShow: "",
      inputman: "",
      isZhan: false,
      imgIcon: false,
      imgIcons: true,
      gridData: [],
      teacherdataarray: [],
      historyProvinces: [],
      historyOrderCitys: [],
      historycounty: [],
      ursents: [],
      ursentsTwo: [],
      isShowCommission: false,
      count: "",
      countAll: "",
      isAnswer: false, //是否显示最上边的按钮
      videos: "",
      workOrders: true,
      CallbackInformation: false,
      showPhone: true,
      showPhones: false,
      isHelp: false,
      isLive: false,
      options: {
        isAutoLogin: true, //隐藏登录界面
        showHold: true,
        showConsult: true,
        showTransfer: true,
        showSatisfaction: true,
        showMute: true,
      },
      workOrder: {
        phone: null,
        workNumber: null,
        callback: 0,
        businessType: "1",
        calltype: "",
        serviceCalltype: "",
        mainUniqueId: "",
      },
      isListion: true, //是否接听
      isListioning: false, //正在接听
      iconImg: false,
      iconImgs: false,
      //通话记录中的录音播放
      isBroadcast: true,
      labelPosition: "right",
      num: "a",
      //最上边的客服状态
      valuezhuangtai: "忙碌",
      CallbackOptions: [
        {
          value: "0",
          label: "是",
        },
        {
          value: "1",
          label: "否",
        },
      ],
      optionszhuangtai: [
        {
          value: "0",
          label: "空闲",
        },
        {
          value: "1",
          label: "忙碌",
        },
        {
          value: "2",
          label: "暂离",
        },
        {
          value: "3",
          label: "手机接听",
        },
      ],
      //下左边
      activeName: "first",
      activeName1: "first",
      valuedate: "2021-10-25",
      input: "",
      tableData: [],
      tableData1: [],
      totalTime1: "", //通话记录总时长
      tableData2: [],
      dataLists: "",
      dataTitle: "",
      dataList: [
        {
          label: "血压相关",
          children: [
            {
              label: "如何正确测量血压",
            },
            {
              label: "血压分级标准",
            },
          ],
        },
        {
          label: "血糖相关",
          children: [
            {
              label: "血糖 1",
            },
            {
              label: "血糖 2",
            },
          ],
        },
        {
          label: "血脂相关",
          children: [
            {
              label: "高血脂形成原因",
            },
            {
              label: "高血脂主要症状",
            },
          ],
        },
      ],
      defaultProps: {
        children: "childs",
        label: "title",
      },
      valueOrder: "",
      historyinput: "",
      historyOrdersex: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "0",
          label: "女",
        },
      ],
      valueOrdersex: "",
      historyProvince: [],
      valueOrderProvince: "",
      historyOrderCity: [],
      valueOrderCity: "",
      radio: "1",
      textarea: "",
      historyOrdertime: [
        {
          value: "1",
          label: "1小时",
        },
        {
          value: "2",
          label: "2小时",
        },
        {
          value: "3",
          label: "3小时",
        },
        {
          value: "6",
          label: "6小时",
        },
        {
          value: "24",
          label: "24小时",
        },
        {
          value: "48",
          label: "48小时",
        },
        {
          value: "72",
          label: "72小时",
        },
        {
          value: "0",
          label: "不限制",
        },
      ],
      valueOrdertime: "",
      historyOrderputong: [
        {
          value: "1",
          label: "紧急",
        },
        {
          value: "2",
          label: "高",
        },
        {
          value: "3",
          label: "普通",
        },

        {
          value: "4",
          label: "低",
        },
      ],
      valueOrderputong: "",
      optionsbase: [
        {
          value: "1",
          label: "昵称",
        },
        {
          value: "2",
          label: "备用手机",
        },
        {
          value: "3",
          label: "IMEI",
        },
        {
          value: "4",
          label: "紧急联系人姓名",
        },
        {
          value: "5",
          label: "紧急联系人电话",
        },
      ],
      valuebase: "",
      inputsize: "",
      formMember: {
        createDate: null,
        updateDate: null,
        leaveDate: null,
        joinDate: null,
      }, //信息查询的基础信息
      formMemberInfo: {
        createDate: null,
        updateDate: null,
        bloodPressureDate: null,
        bloodSugarDate: null,
        heartRateDate: null,
        temperatureDate: null,
        sleepDate: null,
        walkDate: null,
        oxygenDate: null,
      }, //信息查询的激活日期等
      formMembersOne: {
        createDate: null,
        updateDate: null,
      }, //激活日期
      form: {},
      forBody: {}, //健康数据
      morningbefor: {}, //早餐前
      morningafter: {}, //早餐后
      lunchbefor: {}, //午餐前
      lunchafter: {}, //午餐后
      eveningbefor: {}, //晚餐前
      //性别下拉
      optionssex: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "0",
          label: "女",
        },
      ],
      value: "",
      value1: "", //日期下拉
      value2: [], //登记位置
      value3: [], //登记位置
      value4: [], //登记位置
      options2: [
        {
          value: "zhinan",
          label: "指南",
          children: [
            {
              value: "shejiyuanze",
              label: "设计原则",
              children: [
                {
                  value: "yizhi",
                  label: "一致",
                },
                {
                  value: "fankui",
                  label: "反馈",
                },
                {
                  value: "xiaolv",
                  label: "效率",
                },
                {
                  value: "kekong",
                  label: "可控",
                },
              ],
            },
            {
              value: "daohang",
              label: "导航",
              children: [
                {
                  value: "cexiangdaohang",
                  label: "侧向导航",
                },
                {
                  value: "dingbudaohang",
                  label: "顶部导航",
                },
              ],
            },
          ],
        },
        {
          value: "zujian",
          label: "组件",
          children: [
            {
              value: "basic",
              label: "Basic",
              children: [
                {
                  value: "layout",
                  label: "Layout 布局",
                },
                {
                  value: "color",
                  label: "Color 色彩",
                },
                {
                  value: "typography",
                  label: "Typography 字体",
                },
                {
                  value: "icon",
                  label: "Icon 图标",
                },
                {
                  value: "button",
                  label: "Button 按钮",
                },
              ],
            },
            {
              value: "form",
              label: "Form",
              children: [
                {
                  value: "radio",
                  label: "Radio 单选框",
                },
                {
                  value: "checkbox",
                  label: "Checkbox 多选框",
                },
                {
                  value: "input",
                  label: "Input 输入框",
                },
                {
                  value: "input-number",
                  label: "InputNumber 计数器",
                },
                {
                  value: "select",
                  label: "Select 选择器",
                },
                {
                  value: "cascader",
                  label: "Cascader 级联选择器",
                },
                {
                  value: "switch",
                  label: "Switch 开关",
                },
                {
                  value: "slider",
                  label: "Slider 滑块",
                },
                {
                  value: "time-picker",
                  label: "TimePicker 时间选择器",
                },
                {
                  value: "date-picker",
                  label: "DatePicker 日期选择器",
                },
                {
                  value: "datetime-picker",
                  label: "DateTimePicker 日期时间选择器",
                },
                {
                  value: "upload",
                  label: "Upload 上传",
                },
                {
                  value: "rate",
                  label: "Rate 评分",
                },
                {
                  value: "form",
                  label: "Form 表单",
                },
              ],
            },
            {
              value: "data",
              label: "Data",
              children: [
                {
                  value: "table",
                  label: "Table 表格",
                },
                {
                  value: "tag",
                  label: "Tag 标签",
                },
                {
                  value: "progress",
                  label: "Progress 进度条",
                },
                {
                  value: "tree",
                  label: "Tree 树形控件",
                },
                {
                  value: "pagination",
                  label: "Pagination 分页",
                },
                {
                  value: "badge",
                  label: "Badge 标记",
                },
              ],
            },
            {
              value: "notice",
              label: "Notice",
              children: [
                {
                  value: "alert",
                  label: "Alert 警告",
                },
                {
                  value: "loading",
                  label: "Loading 加载",
                },
                {
                  value: "message",
                  label: "Message 消息提示",
                },
                {
                  value: "message-box",
                  label: "MessageBox 弹框",
                },
                {
                  value: "notification",
                  label: "Notification 通知",
                },
              ],
            },
            {
              value: "navigation",
              label: "Navigation",
              children: [
                {
                  value: "menu",
                  label: "NavMenu 导航菜单",
                },
                {
                  value: "tabs",
                  label: "Tabs 标签页",
                },
                {
                  value: "breadcrumb",
                  label: "Breadcrumb 面包屑",
                },
                {
                  value: "dropdown",
                  label: "Dropdown 下拉菜单",
                },
                {
                  value: "steps",
                  label: "Steps 步骤条",
                },
              ],
            },
            {
              value: "others",
              label: "Others",
              children: [
                {
                  value: "dialog",
                  label: "Dialog 对话框",
                },
                {
                  value: "tooltip",
                  label: "Tooltip 文字提示",
                },
                {
                  value: "popover",
                  label: "Popover 弹出框",
                },
                {
                  value: "card",
                  label: "Card 卡片",
                },
                {
                  value: "carousel",
                  label: "Carousel 走马灯",
                },
                {
                  value: "collapse",
                  label: "Collapse 折叠面板",
                },
              ],
            },
          ],
        },
        {
          value: "ziyuan",
          label: "资源",
          children: [
            {
              value: "axure",
              label: "Axure Components",
            },
            {
              value: "sketch",
              label: "Sketch Templates",
            },
            {
              value: "jiaohu",
              label: "组件交互文档",
            },
          ],
        },
      ],
      phones: null,
      //生活状况
      lifeform: {
        name: "",
        phone: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      searchs: "",
      //生活状况 居住
      live: [
        {
          value: "1",
          label: "与子女住",
        },
        {
          value: "2",
          label: "独居（无保姆）",
        },
      ],
      valueLive: "",
      zhiLi: [
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "不正常",
        },
      ],
      valuezhiLi: "",
      xingge: [
        {
          value: "1",
          label: "外向/热情",
        },
        {
          value: "2",
          label: "内向",
        },
      ],
      valuexingge: "",
      yuyan: [
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "不正常",
        },
      ],
      valueyuyan: "",
      tingli: [
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "不正常",
        },
      ],
      valuetingli: "",
      shili: [
        {
          value: "1",
          label: "强",
        },
        {
          value: "2",
          label: "弱",
        },
      ],
      valueshili: "",
      isplays: false,
      xingdong: [
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "不正常",
        },
      ],
      valuexingdong: "",
      manxing: [
        {
          value: "1",
          label: "高血压",
        },
        {
          value: "2",
          label: "糖尿病",
        },
        {
          value: "3",
          label: "高脂血综合征",
        },
      ],
      ids: "",
      valuemanxing: "",
      //铃响
      shrinkPacket: "",
      color: "red",
      //来电
      currentTime: 0,
      timeObj: null, // 时间对象,下方会用到
      myHours: "00", // 我定义来接收计算出来的 小时 的
      myMinutes: "00", // 我定义来接收计算出来的 分钟 的
      mySeconds: "00", // 我定义来接收计算出来的 秒钟 的
      currentTime1: 0,
      timeObj1: null, // 时间对象,下方会用到
      myHours1: "00", // 我定义来接收计算出来的 小时 的
      myMinutes1: "00", // 我定义来接收计算出来的 分钟 的
      mySeconds1: "00", // 我定义来接收计算出来的 秒钟 的
      currentTime2: 0,
      timeObj2: null, // 时间对象,下方会用到
      myHours2: "00", // 我定义来接收计算出来的 小时 的
      myMinutes2: "00", // 我定义来接收计算出来的 分钟 的
      mySeconds2: "00", // 我定义来接收计算出来的 秒钟 的
      //历史工单
      historyList: [],
      tel: "18135128546",
      cno: "",
      //回拨的信息收集
      name: "",
      xianName: "",
      phone: "",
      //定时器
      timer: null,
      timer1: null,
      timer2: null,
      // timer3: null,
      timer4: null,
      timer5: null,
      timer7: null,
      Personstatus: "空闲中",
      userId: "", //关联客服id
      cityName: "",
      countyName: "",
      cityNames: "",
      provinceName: "",
      workOrderList: {},
      listvalue: "",
      formDate: {
        name: "",
        sparePhone: "",
        equipmentNo: "",
        emergencyContact: "",
        emergencyContactPhone: "",
      },
      textPhone: null, //获取电话号
      phoneOne: null,
      remaining: {},
      idList: null,
      muteCount: 0,
      //关联人员
      tableDataPerson: [{ name: "王小虎" }],
      quitedId: "",
      gaipei: "",
      quFinish: {}, //完成的参数
      beee: null, //被踢
      cnoStatus: "", //客服状态
      qiniuUrls: null,
      statusOne: "0",
      statusOnes: "0",
      dataPhone: "1",
      infoFlags: false,
      cityId: "",
      countyId: "",
      citysId: "",
      countysId: "",
      isDiv: false,
      mobilePhone: null,
      queryPhone: null,
      emergencyOnes: null,
      emergencyTwos: null,
      idName: null,
      idNames: null,
      // callBackPhoneFirst:null,
      Sphone: "",
      loginToken: "",
      strPhone: null,
      strUser: null,
      strequipmentId: null,
      strMessage: "",
      strendReason: "",
      mscode: null,
      mainUniqueIds: "",
      strcode: null,
      lockReconnect: false, //是否真正建立连接
      timeout: 1200000, //58秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
      cusClass: "",
      xianPhone: "",
    };
  },
  created() {
    //获取cno
    let cno = JSON.parse(localStorage.getItem("cno"));
    this.cno = cno;
    //判断是否为客服
    let cus = JSON.parse(localStorage.getItem("cusSer"));
    this.cusClass = cus;
    if (cus == "1") {
      // this.initWebSocket();
      //客服状态
      let pp = JSON.parse(localStorage.getItem("serverStatus"));
      this.myStatus(pp);
      if (pp == "1") {
        this.valuezhuangtai = "忙碌";
      } else if (pp == "0") {
        this.valuezhuangtai = "空闲";
      }
    } else {
      //不是客服
      this.isListion = false;
      this.isListioning = false;
      this.isDiv = true;
      clearInterval(this.timer);
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      // clearInterval(this.timer3);
      clearInterval(this.timer4);
      clearInterval(this.timers);
      // clearInterval(this.timer5);
      clearInterval(this.timer6);
      clearInterval(this.tokenM);
    }
    //获取本地
    let beiti = JSON.parse(localStorage.getItem("initcno"));
    this.beee = beiti;
    //获取关联客服id
    let loginUserId = JSON.parse(localStorage.getItem("loginuser"));
    this.userId = loginUserId.id;
    this.loginToken = loginUserId.token;
    this.testTimer2();
    if (loginUserId.post == "2") {
      this.isShowCommission = true;
    }
    this.getFourCommissions();
    //全部待办工单
    this.allGetFourCommission();
    // this.getHistoryOrderUser();
    this.offenKnow();

    //获取省份城市接口
    api.getCitys({ parentId: 0 }).then((res) => {
      // this.historyOrderCity = res.data.result;
      this.historyProvince = res.data.result; //渲染省
    });
    //获取省份城市接口
    api.AreaCity({ parentId: 0 }).then((res) => {
      // this.historyOrderCity = res.data.result;
      this.historyProvinces = res.data.result; //渲染省
    });
    //查询机构下客服类型所有客服接口
    api.getallTypeUser({ serviceType: 3 }).then((res) => {
      // console.log('查询机构下客服类型所有客服接口:',res.data);
      if (res.data.code == 200) {
        this.tableDataPerson = res.data.result;
      } else {
        this.$message.error(res.data.msg);
      }
    });
    this.tokenM = setInterval(this.tokenMethods, 60000);
  },
  mounted() {
    this.audioElement = document.getElementsByName("audioOne"); // 获取页面上所有的video对象
    console.log(this.audioElement, "输出音频");
    let domContainer = this.$refs.clink;
    new ClinkToolbar(this.options, domContainer, this.callback);
    //console.log(ClinkToolbar);
    this.shout();
    // let klk=JSON.parse(localStorage.getItem("crossHere"));
    // if(klk==true){
    //   this.listion();
    // }
    // clearInterval(this.timer3);
    this.$once("hook:beforeDestroy", () => {
      // clearInterval(this.timer3);
    });
    this.dateTime();
    //判断是否为客服
    let cus = JSON.parse(localStorage.getItem("cusSer"));
    if (cus !== "1") {
      //不是客服
      this.isListion = false;
      this.isListioning = false;
      this.isDiv = true;
      clearInterval(this.timer);
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      // clearInterval(this.timer3);
      clearInterval(this.timer4);
      clearInterval(this.tokenM);
      // clearInterval(this.timer5);
      // clearInterval(this.timer7);
    }
    // 添加socket通知监听
    window.addEventListener("onmessageWS", this.getSocketData);
  },
  watch: {
    searchs(val) {
      this.$refs.tree.filter(val);
      console.log(val, "控件输出");
    },
    twoType(dd) {
      console.log("传到400的值：", dd);
      if (dd == "2") {
        this.listion();
      }
    },
    serStatusee(aa) {
      console.log("传到400的客服状态：", aa);
      this.myStatus(aa);
      if (aa == "1") {
        this.valuezhuangtai = "忙碌";
      } else if (aa == "0") {
        this.valuezhuangtai = "空闲";
      }
    },
    seHere(bb) {
      console.log("传到400接听：", bb);
      if (bb == true) {
        this.isFour = false;
        this.isServer = false;
        this.isServer1 = false;
        clearInterval(this.timer4);
        clearInterval(this.timer);
        this.$refs.audioLing.pause();
        this.currentTime2 = 0;
        this.testTimer2();
        this.isAnswer = false;
      }
    },
  },
  methods: {
    zhuang() {
      this.isFour = false;
      this.isServer = false;
      this.isServer1 = false;
      clearInterval(this.timer4);
      clearInterval(this.timer);
      this.$refs.audioLing.pause();
      this.currentTime2 = 0;
      this.testTimer2();
      this.isAnswer = false;
    },
    tokenMethods() {
      //获取关联客服id
      let loginUserId = JSON.parse(localStorage.getItem("token"));
      this.loginToken = loginUserId;
      api.checkTokens({ token: this.loginToken }).then((res) => {
        if (res.data.code == 200) {
          console.log(res);
        } else {
          this.$message.error(res.data.msg);
          this.$router.push("/");
        }
      });
    },
    resetForm() {
      this.gridData = [];
      Object.keys(this.formMember).forEach((item) => {
        this.$delete(this.formMember, item);
      });
      Object.keys(this.formMemberInfo).forEach((item) => {
        this.$delete(this.formMemberInfo, item);
      });
      this.historyList = undefined;
      this.formMember.sparePhone = "";
      this.formMember.city = "";
      this.formMember.county = "";
      this.valuebase = "";
      this.inputsize = "";
    },
    // close() {
    //   this.websock.close(); //关闭websocket
    //   this.websock.onclose = function (e) {
    //     console.log(e); //监听关闭事件
    //     console.log("关闭");
    //     // this.initWebSocket();
    //   };
    // },
    // initWebSocket() {
    //   // var ws = "wss://erp.careld.cn/socket";
    //   var ws = "wss://dev.careld.cn/socket";
    //   this.websock = new WebSocket(ws);
    //   // 判断是否websocket是否已经打开，如果已经打开则直接发送数据  readyState
    //   if (this.websock.readyState !== WebSocket.OPEN) {
    //     this.websock.onopen = this.websocketonopen;
    //   } else {
    //     var objn = JSON.stringify({
    //       cno: this.cno,
    //       msgcode: "201",
    //       source: "200116212001230",
    //       token: this.loginToken,
    //     });
    //     this.websocketsend(objn);
    //   }
    //   // this.websock.onopen = this.websocketonopen;
    //   this.websock.onerror = this.websocketonerror;
    //   this.websock.onmessage = this.websocketonmessage;
    //   this.websock.onclose = this.websocketclose;
    // },
    // reconnect() {
    //   //重新连接
    //   var that = this;
    //   if (that.lockReconnect) {
    //     return;
    //   }
    //   that.lockReconnect = true;
    //   //没连接上会一直重连，设置延迟避免请求过多
    //   that.timeoutnum && clearTimeout(that.timeoutnum);
    //   that.timeoutnum = setTimeout(() => {
    //     //新连接
    //     this.initWebSocket();
    //     that.lockReconnect = false;
    //   }, 10000);
    // },
    // resets() {
    //   //重置心跳
    //   var that = this;
    //   //清除时间
    //   clearTimeout(that.timeoutObj);
    //   clearTimeout(that.serverTimeoutObj);
    //   //重启心跳
    //   that.start();
    // },
    // start() {
    //   //开启心跳
    //   console.log("开启心跳");
    //   var self = this;
    //   self.timeoutObj && clearTimeout(self.timeoutObj);
    //   self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
    //   self.timeoutObj = setTimeout(function () {
    //     //这里发送一个心跳，后端收到后，返回一个心跳消息，
    //     if (this.websock.readyState == 1) {
    //       //如果连接正常
    //       var timeout = JSON.stringify({
    //         cno: this.cno,
    //         msgcode: 200,
    //         source: "",
    //         token: this.loginToken,
    //       });
    //       this.websocketsend(timeout);
    //     } else {
    //       //否则重连
    //       this.initWebSocket();
    //     }
    //     self.serverTimeoutObj = setTimeout(function () {
    //       //超时关闭
    //       this.websock.close();
    //     }, self.timeout);
    //   }, self.timeout);
    // },
    //  连接建立之后执行send方法发送数据
    // websocketonopen() {
    //   var objn = JSON.stringify({
    //     cno: this.cno,
    //     msgcode: "201",
    //     source: "200116212001230",
    //     token: this.loginToken,
    //   });
    //   this.websocketsend(objn);
    // },
    //  数据接收
    // websocketonmessage(e) {
    getSocketData(e) {
      // console.log("服务器返回:" + e.data);
      console.log("服务器返回:" + e.detail.data);
      //获取cno
      let cno = JSON.parse(localStorage.getItem("cno"));
      this.cno = cno;
      // var str = e.data;
      var str = e.detail.data;
      var strname = JSON.parse(str);
      // if (e.data && strname.msgcode != 100) {
      // if (e.detail.data && strname.msgcode != 100) {
      //   var messagelist = JSON.stringify({
      //     cno: this.cno,
      //     msgcode: 200,
      //     source: strname.msgno,
      //     sourcecode: strname.msgcode,
      //     token: this.loginToken,
      //   });
      //   this.websocketsend(messagelist);
      // }
      if (strname.code == 500) {
        // this.$message.error("消息参数错误!");
        this.$message.error(strname.message);
        return false;
      } else if (strname.code == 10000) {
        this.$message.error(strname.message);
        this.$router.push("/");
        clearInterval(this.timer);
        clearInterval(this.timer1);
        clearInterval(this.timer2);
        clearInterval(this.timer4);
        clearInterval(this.tokenM);
      }
      if (strname.msgcode == 110) {
        this.textPhone = strname.phone;
        this.phoneOne = strname.phone;
        this.xianPhone = strname.phone;
        this.xianPhone =
          this.xianPhone.substr(0, 3) + "****" + this.xianPhone.substr(7);
        this.strUser = strname.userid;
        this.strequipmentId = strname.equipmentId;
        this.strMessage = strname.message;
        this.strendReason = strname.endReason;
        this.mscode = strname.msgcode;
        this.mainUniqueIds = strname.mainUniqueId;
        this.seType = strname.serviceType;
        localStorage.setItem("strcodes", JSON.stringify(strname.msgcode));
        localStorage.setItem("listionPhone", JSON.stringify(strname.phone));
        localStorage.setItem("serPhone", JSON.stringify(strname.phone));
        localStorage.setItem("serEquId", JSON.stringify(strname.equipmentId));
        localStorage.setItem(
          "strequipmentIds",
          JSON.stringify(strname.equipmentId)
        );
        localStorage.setItem("serUserId", JSON.stringify(strname.userid));
        localStorage.setItem("userId", JSON.stringify(strname.userid));
        this.shout();
        localStorage.setItem("strcodes", JSON.stringify(strname.msgcode));
        if (strname.serviceType == 3) {
          //400
          this.isFour = true;
          this.isServer = false;
          this.isServer1 = false;
        } else if (strname.serviceType == 1) {
          //客服
          this.isFour = false;
          this.isServer = false;
          this.isServer1 = true;
        }
      } else if (strname.msgcode == 112) {
        this.textPhone = strname.phone;
        this.phoneOne = strname.phone;
        this.xianPhone = strname.phone;
        this.xianPhone =
          this.xianPhone.substr(0, 3) + "****" + this.xianPhone.substr(7);
        localStorage.setItem("strcodes", JSON.stringify(strname.msgcode));
        localStorage.setItem("listionPhone", JSON.stringify(strname.phone));
        localStorage.setItem("serPhone", JSON.stringify(strname.phone));
        localStorage.setItem("serEquId", JSON.stringify(strname.equipmentId));
        localStorage.setItem(
          "strequipmentIds",
          JSON.stringify(strname.equipmentId)
        );
        localStorage.setItem("serUserId", JSON.stringify(strname.userid));
        localStorage.setItem("userId", JSON.stringify(strname.userid));
        this.seType = strname.serviceType;
        this.strUser = strname.userid;
        this.strequipmentId = strname.equipmentId;
        this.strMessage = strname.message;
        this.strendReason = strname.endReason;
        this.mscode = strname.msgcode;
        this.mainUniqueIds = strname.mainUniqueId;
        this.shout();
        if (strname.serviceType == 3) {
          //400
          this.isFour = true;
          this.isServer = false;
          this.isServer1 = false;
        } else if (strname.serviceType == 1) {
          //客服
          this.isFour = false;
          this.isServer = true;
          this.isServer1 = false;
        }
      } else if (strname.msgcode == 120) {
        // this.$message("通话记录正在加载，请耐心等待一分钟后查看！");
        this.endReason();
        this.endReasons();
        this.isFour = false;
        this.isServer = false;
        this.isServer1 = false;
        this.hereLiS = false;
        this.isArrangement = false;
        this.isArrangement1 = true;
        clearInterval(this.timer);
        localStorage.removeItem("phoneNumber");
        this.$refs.audioLing.pause();
      } else if (strname.msgcode == 130) {
        this.mainUniqueIds = strname.mainUniqueId;
        localStorage.setItem(
          "UniqueIdnFour",
          JSON.stringify(this.mainUniqueIds)
        );
      } else if (strname.msgcode == 101) {
        this.valuezhuangtai = "空闲";
        this.cnoStatus = strname.status;
        localStorage.setItem("serverStatus", JSON.stringify(this.cnoStatus));
        // this.myStatus(this.cnoStatus);
        this.isArrangement = true;
        this.isArrangement1 = false;
        clearInterval(this.timer4);
        this.currentTime2 = 0;
        this.testTimer2();
        this.isFour = false;
        this.isServer = false;
        this.isServer1 = false;
        this.hereLiS = false;
        localStorage.removeItem("phoneNumber");
      }
      if (strname.msgcode == 111 || strname.msgcode == "111") {
        this.isFour = false;
        this.isServer = false;
        this.isServer1 = false;
        this.hereLiS = false;
        this.shout();
        //铃响
        clearInterval(this.timer);
        this.$refs.audioLing.pause();
        this.isListion = true;
        this.isListioning = false;
        if (this.isAnswer == true) {
          this.isAnswer = false;
        }
        localStorage.removeItem("phoneNumber");
        this.cnoStatus = 1;
        console.log("走到这一步0000");
      }
      this.textPhone = strname.phone;
      this.phoneOne = strname.phone;
      this.strUser = strname.userid;
      this.strequipmentId = strname.equipmentId;
      this.strMessage = strname.message;
      this.strendReason = strname.endReason;
      this.mscode = strname.msgcode;
      this.mainUniqueIds = strname.mainUniqueId;
      localStorage.setItem("strnamePhone", JSON.stringify(strname.phone));
      // this.resets();
      console.log(this.mainUniqueIds, "xinjiayi123");
      this.strcode = strname.code;
      console.log("服务器返回:" + strname);
    },
    //  数据发送
    websocketsend(Data) {
      // this.websock.send(Data);
      this.global.ws.send(Data);
      console.log(`数据发送` + Data);
    },
    websocketonerror(e) {
      console.log(e, "假连接");
      let that = this;
      var str = e.data;
      var strname = JSON.parse(str);
      let loginUserId = JSON.parse(localStorage.getItem("loginuser"));
      that
        .$http({
          url: "/too/center/weblog/save",
          params: {
            callback: "",
            errorCode: strname.code,
            errorExplain: "断开连接",
            interfaceUrl: this.global.ws.url,
            menuName: "400中心-webSocket链接",
            menuUrl: that.$route.path,
            params: strname,
            source: "客服系统",
            userId: loginUserId.id,
            userName: loginUserId.name,
          },
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
          method: "get",
        })
        .then((res) => {
          if (res.data.code === 200) {
            console.log();
          }
        });
      console.log("连接失败重新连接");

      this.initWebSocket();
    },
    // //  关闭
    websocketclose(e) {
      console.log("断开连接", e);
      let that = this;
      var str = e.data;
      var strname = JSON.parse(str);
      let loginUserId = JSON.parse(localStorage.getItem("loginuser"));
      that
        .$http({
          url: "/too/center/weblog/save",
          params: {
            callback: "",
            errorCode: strname.code,
            errorExplain: "断开连接",
            interfaceUrl: this.global.ws.url,
            menuName: "400中心-webSocket链接",
            menuUrl: that.$route.path,
            params: strname,
            source: "客服系统",
            userId: loginUserId.id,
            userName: loginUserId.name,
          },
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
          method: "get",
        })
        .then((res) => {
          if (res.data.code === 200) {
            console.log();
          }
        });
      this.reconnect();
      // this.initWebSocket();
    },
    onclick(index) {
      this.isactive = index;
    },
    //慢性病
    zhanChange(val) {
      this.formMemberInfo.illness += val + ",";
      console.log("====?", val);
      this.isZhan = false;
      this.inputman = "";
    },
    //紧急联系人
    emergencyContact(val) {
      console.log(this.ursents, "输出对");
      for (var i = 0; i < this.ursents.length; i++) {
        this.userPhone = this.ursents[i];
        if (val === this.userPhone.userName) {
          this.formMember.emergencyContactOnePhone = this.userPhone.phone;
          let str = this.formMember.emergencyContactOnePhone;
          this.formMember.emergencyContactOnePhone = str.replace(
            /(\d{3})\d{4}(\d{1})/,
            "$1****$2"
          );
          this.idName = this.userPhone.id;
          //对于电话的处理
        }
      }
    },
    //紧急联系人2
    emergencyContacts(val) {
      console.log(this.ursentsTwo, "输出对");
      for (var i = 0; i < this.ursentsTwo.length; i++) {
        this.userPhones = this.ursentsTwo[i];
        if (val === this.userPhones.userName) {
          this.formMember.emergencyContactTwoPhone = this.userPhones.phone;
          let str = this.formMember.emergencyContactTwoPhone;
          this.formMember.emergencyContactTwoPhone = str.replace(
            /(\d{3})\d{4}(\d{1})/,
            "$1****$2"
          );
          this.idNames = this.userPhones.id;
          //对于电话的处理
        }
      }
    },
    //回拨事件
    callBackMessage() {
      if (this.cnoStatus != "0" || this.cnoStatus != 0) {
        this.$message.error("当前忙碌，请稍等！");
        return false;
      } else {
        var objmeg = JSON.stringify({
          cno: this.cno,
          msgcode: 210,
          token: this.loginToken,
          phone: this.mobilePhone || "",
          userid: 0,
          equipmentid: null,
          calltype: "2",
        });
        this.websocketsend(objmeg);
        this.calltypes = "2";
        localStorage.setItem("phoneNumber", JSON.stringify(this.mobilePhone));
        this.mainUniqueId = "";
        this.size = "呼叫中";
        //页面跳转
        this.isListion = false;
        this.isListioning = true;
        //记时通话时间
        clearInterval(this.timer2);
        this.currentTime1 = 0;
        this.testTimer1();
        //客服状态置忙
        this.valuezhuangtai = "忙碌";
        this.cnoStatus = 1;
        //改变客服状态下的计时器
        clearInterval(this.timer4);
        this.currentTime2 = 0;
        this.testTimer2();
        let hh = this.mobilePhone;
        // console.log('是否获取到电话号码在回拨前：',this.mobilePhone);
        // localStorage.setItem("hhName", JSON.stringify(hh));
        // console.log(hh, "123123输出");
        // //回拨前调用的接口
        // let backObj = {
        //   con: this.cno,
        //   phone: hh,
        //   userId: this.backUserId,
        // };
        // apiShout.getbackInfor(backObj).then((res) => {
        //   if (res.data.code === 200) {
        //     console.log("回拨前调用的接口：", res.data);
        //   } else {
        //     this.$message.error(res.data.msg);
        //   }
        // });
        if (hh != null) {
          const audioElement = this.audioElement;
          if (audioElement && audioElement.length > 0) {
            for (let i = 0; i < audioElement.length; i++) {
              this.audioElement[i].pause();
            }
          }
          // clearInterval(this.timer3);
          // clearInterval(this.timer5);
        }
        apiShout.getparTitle({ title: "外呼外显" }).then((res) => {
          // console.log('转接：',res.data);
          if (res.data.code == 200) {
            console.log(res.data.result, "res.data.result400");
            //存储本地
            // localStorage.setItem("phone", JSON.stringify(hh));
            ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
              // 传入参数对象
              var params = {};
              params.tel = hh;
              params.obClid = res.data.result;
              // params.obClid = "01021388142";
              // params.obClid = "02138185919";
              ClinkAgent.previewOutcall(params);
              ClinkAgent.registerListener(
                ClinkAgent.EventType.KICKOUT,
                function (token) {
                  console.log("被踢下线=====================：", token);
                  if (token.cno == this.cno) {
                    //跳转登录界面
                    this.$router.push("/");
                    this.$message.error("不好意思，您被踢下线");
                    //退出天润的登录
                    ClinkAgent.setup(
                      { sipPhone: true, debug: false },
                      function () {
                        // 传入参数对象
                        var params = {};
                        params.logoutMode = 1;
                        params.removeBinding = 0;
                        ClinkAgent.logout(params);
                      }
                    );
                    clearInterval(this.timer);
                    clearInterval(this.timer1);
                    clearInterval(this.timer2);
                    // clearInterval(this.timer3);
                    clearInterval(this.timer4);
                    clearInterval(this.tokenM);
                    // clearInterval(this.timer5);
                    // clearInterval(this.timer7);
                    console.log("判断了-------------");
                  }
                }
              ); // 被踢下线
              ClinkAgent.registerListener(
                ClinkAgent.EventType.PREVIEW_OUTCALL,
                function (token) {
                  console.log("外呼结果：", token);
                }
              ); // 预览外呼
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
        this.xianPhone = this.mobilePhone;
        this.xianPhone =
          this.xianPhone.substr(0, 3) + "****" + this.xianPhone.substr(7);
        // //请求电话通话记录ID
        // apiShout.getcallHistory({ phone: this.localPhone }).then((res) => {
        //   console.log("电话通话记录", res.data);
        //   this.mainUniqueId=res.data.result;
        //   console.log('回拨的main',this.mainUniqueId);
        // });
        this.statusOnes = "0";
        //请求挂机接口
        // this.timer7 = setInterval(this.endReasons, 1000);
      }
    },
    dateTime() {
      //获取通话记录
      let date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      this.timeDateShow = year + seperator1 + month + seperator1 + strDate;
      console.log(this.timeDateShow, "输出当前时间");
      apiShout.getFooCallHis({ time: this.timeDateShow }).then((res) => {
        //console.log("通话记录：", res.data);
        this.$forceUpdate(); //重新渲染组件
        if (res.data.code === 200) {
          this.tableData1 = res.data.result.data;
          // 对通话时长的处理
          for (let i = 0; i < res.data.result.data.length; i++) {
            if (res.data.result.data[i].bridgeDuration == null) {
              res.data.result.data[i].bridgeDuration = "00:00:00";
            } else {
              res.data.result.data[i].bridgeDuration = this.format(
                res.data.result.data[i].bridgeDuration
              );
            }
            if (
              res.data.result.data[i].startTime == "" ||
              res.data.result.data[i].startTime == null
            ) {
              res.data.result.data[i].startTime =
                res.data.result.data[i].createDate;
            }
            if (res.data.result.data[i].callMode == "未接通") {
              res.data.result.data[i].qiniuUrl = null;
            }
          }
          this.tableData1.forEach((item) => {
            let str = item.customerNumber;
            item.NewcustomerNumber = str.replace(
              /(\d{3})\d{4}(\d{1})/,
              "$1****$2"
            );
          });
          this.totalTime1 = this.format(res.data.result.totalTime);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // //新增慢性病
    addInput() {
      this.isZhan = true;
    },
    timeDate(val) {
      var seperator1 = "-";
      var year = val.getFullYear();
      var month = val.getMonth() + 1;
      var strDate = val.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      val = year + seperator1 + month + seperator1 + strDate;
      console.log(val, "输出日期格式1");
      apiShout.getFooCallHis({ time: val }).then((res) => {
        //console.log("通话记录：", res.data);
        if (res.data.code === 200) {
          this.tableData1 = res.data.result.data;
          // 对通话时长的处理
          for (let i = 0; i < res.data.result.data.length; i++) {
            if (res.data.result.data[i].bridgeDuration == null) {
              res.data.result.data[i].bridgeDuration = "00:00:00";
            } else {
              res.data.result.data[i].bridgeDuration = this.format(
                res.data.result.data[i].bridgeDuration
              );
            }
            if (
              res.data.result.data[i].startTime == "" ||
              res.data.result.data[i].startTime == null
            ) {
              res.data.result.data[i].startTime =
                res.data.result.data[i].createDate;
            }
            if (res.data.result.data[i].callMode == "未接通") {
              res.data.result.data[i].qiniuUrl = null;
            }
          }
          this.tableData1.forEach((item) => {
            let str = item.customerNumber;
            item.NewcustomerNumber = str.replace(
              /(\d{3})\d{4}(\d{1})/,
              "$1****$2"
            );
          });
          this.totalTime1 = this.format(res.data.result.totalTime);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //静音
    jinyin() {
      this.muteCount++;
      if (this.muteCount % 2 === 0) {
        this.isYIN = true;
        this.isJIN = false;
        //取消静音
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.direction = "in";
          ClinkAgent.unmute(params);
        });
      } else {
        this.isJIN = true;
        this.isYIN = false;
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.direction = "in";
          ClinkAgent.mute(params);
        });
      }
    },
    //保存用户信息
    preservation() {
      this.$confirm("是否保存此信息?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // this.formMember.city = this.cityName;
          // this.formMember.county = this.countyName;
          this.formMember.createDate = [];
          this.formMember.updateDate = [];
          this.formMember.joinDate = [];
          this.formMember.leaveDate = [];
          this.formMemberInfo.createDate = [];
          this.formMemberInfo.updateDate = [];
          this.formMemberInfo.bloodPressureDate = [];
          this.formMemberInfo.bloodSugarDate = [];
          this.formMemberInfo.heartRateDate = [];
          this.formMemberInfo.temperatureDate = [];
          this.formMemberInfo.sleepDate = [];
          this.formMemberInfo.walkDate = [];
          this.formMemberInfo.oxygenDate = [];
          // console.log(obj,"shuchuobj看一下")
          // let date = this.formMember.birthday;
          // var seperator1 = "-";
          // var year = date.getFullYear();
          // var month = date.getMonth() + 1;
          // var strDate = date.getDate();
          // if (month >= 1 && month <= 9) {
          //   month = "0" + month;
          // }
          // if (strDate >= 0 && strDate <= 9) {
          //   strDate = "0" + strDate;
          // }
          // this.formMember.birthday =
          //   year + seperator1 + month + seperator1 + strDate;
          apiShout
            .updateMembers(
              this.formMemberInfo,
              this.formMemberInfo.createDate,
              this.formMemberInfo.updateDate,
              this.formMemberInfo.bloodPressureDate,
              this.formMemberInfo.bloodSugarDate,
              this.formMemberInfo.heartRateDate,
              this.formMemberInfo.temperatureDate,
              this.formMemberInfo.sleepDate,
              this.formMemberInfo.walkDate,
              this.formMemberInfo.oxygenDate
            )
            .then((res) => {
              this.messageCode = res.data.code;

              if (res.data.code === 200) {
                if (this.messageCodes !== 500 && this.messageCodes === 200) {
                  console.log(res, "res保存生活状况信息");
                  // this.$message({
                  //   type: "success",
                  //   message: "保存成功!",
                  // });
                  apiShout
                    .getMemberInfoByIds({ id: this.idList })
                    .then((res) => {
                      if (res.data.code === 200) {
                        //对于生日的处理
                        let a = res.data.result.member.birthday;
                        let dd = new Date(a);
                        let y = dd.getFullYear();
                        let m = dd.getMonth() + 1;
                        m = m < 10 ? "0" + m : m;
                        let d = dd.getDate();
                        d = d < 10 ? "0" + d : d;
                        res.data.result.member.birthday = y + "-" + m + "-" + d;
                        console.log(res, "查询用户信息");
                        this.formMember = res.data.result.member;
                        this.emergencyOnes =
                          res.data.result.member.emergencyOne;
                        this.emergencyTwos =
                          res.data.result.member.emergencyTwo;
                        this.formMember.birthday =
                          res.data.result.member.birthday;
                        this.Sphone = res.data.result.member.sparePhone;
                        if (this.formMember.sparePhone != undefined) {
                          let str = this.formMember.sparePhone;
                          this.formMember.sparePhones = str.replace(
                            /(\d{3})\d{4}(\d{1})/,
                            "$1****$2"
                          );
                        }
                        // this.formMember = row.memberInfo;
                        this.formMemberInfo = res.data.result.memberInfo;
                        this.formMemberInfo.live =
                          res.data.result.memberInfo.live;
                        this.formMemberInfo.intellect =
                          res.data.result.memberInfo.intellect;
                        this.formMemberInfo.character =
                          res.data.result.memberInfo.character;
                        this.formMemberInfo.language =
                          res.data.result.memberInfo.language;
                        this.formMemberInfo.hearing =
                          res.data.result.memberInfo.hearing;
                        this.formMemberInfo.vision =
                          res.data.result.memberInfo.vision;
                        this.formMemberInfo.action =
                          res.data.result.memberInfo.action;
                        this.formMemberInfo.illness =
                          res.data.result.memberInfo.illness;
                        this.formMember.createDate =
                          res.data.result.memberInfo.createDate;
                        this.formMember.updateDate =
                          res.data.result.memberInfo.updateDate;
                        this.forBody = res.data.result.memberInfo;
                        this.cityName = res.data.result.member.city;
                        this.countyName = res.data.result.member.county; //TextToCode
                        this.countyId = res.data.result.member.county;
                        this.cityId = res.data.result.member.city;
                        this.formMember.city = CodeToText[this.cityName];
                        this.formMember.county = CodeToText[this.countyName];
                        console.log(this.familyIds, "家庭id");
                        this.ursents = [];
                        this.ursentsTwo = [];
                        apiShout
                          .getFamilyUsers({ fId: this.familyIds })
                          .then((res) => {
                            if (res.data.code === 200) {
                              console.log(res, "紧急联系人");
                              // this.ursents = res.data.result
                              res.data.result.forEach((item) => {
                                this.ursents.push(item);
                                this.ursentsTwo.push(item);
                              });
                              for (var i = 0; i < this.ursents.length; i++) {
                                this.userPhone = this.ursents[i];
                                if (this.userPhone.id === this.emergencyOnes) {
                                  this.formMember.emergencyContactOnePhone =
                                    this.userPhone.phone;
                                  this.formMember.emergencyContactOne =
                                    this.userPhone.userName;
                                  let str =
                                    this.formMember.emergencyContactOnePhone;
                                  this.formMember.emergencyContactOnePhone =
                                    str.replace(
                                      /(\d{3})\d{4}(\d{1})/,
                                      "$1****$2"
                                    );
                                }
                              }
                              for (let i = 0; i < this.ursentsTwo.length; i++) {
                                this.userPhones = this.ursentsTwo[i];
                                if (this.userPhones.id === this.emergencyTwos) {
                                  this.formMember.emergencyContactTwoPhone =
                                    this.userPhones.phone;
                                  this.formMember.emergencyContactTwo =
                                    this.userPhones.userName;
                                  let str =
                                    this.formMember.emergencyContactTwoPhone;
                                  this.formMember.emergencyContactTwoPhone =
                                    str.replace(
                                      /(\d{3})\d{4}(\d{1})/,
                                      "$1****$2"
                                    );
                                }
                              }
                            } else {
                              this.$message.error(res.data.msg);
                            }
                          });
                      } else {
                        this.$message.error(res.data.msg);
                      }
                    });
                }
              } else {
                this.$message.error(res.data.msg);
              }
            });
          this.infoFlags = true;
          if (this.infoFlags == true) {
            this.formMember.city = this.cityId;
            this.formMember.county = this.countyId;
            this.formMember.sparePhone = this.Sphone;
          }
          this.formMember.emergencyOne = this.idName;
          this.formMember.emergencyTwo = this.idNames;
          apiShout
            .updates(
              this.formMember,
              this.formMember.sparePhone,
              this.formMember.createDate,
              this.formMember.updateDate,
              this.formMember.joinDate,
              this.formMember.leaveDate,
              this.formMember.birthday
            )
            .then((res) => {
              this.messageCodes = res.data.code;
              if (res.data.code === 200) {
                this.$message({
                  type: "success",
                  message: "保存成功!",
                });
                apiShout.getMemberInfoByIds({ id: this.idList }).then((res) => {
                  if (res.data.code === 200) {
                    //对于生日的处理
                    let a = res.data.result.member.birthday;
                    let dd = new Date(a);
                    let y = dd.getFullYear();
                    let m = dd.getMonth() + 1;
                    m = m < 10 ? "0" + m : m;
                    let d = dd.getDate();
                    d = d < 10 ? "0" + d : d;
                    res.data.result.member.birthday = y + "-" + m + "-" + d;
                    console.log(res, "查询用户信息");
                    this.formMember = res.data.result.member;
                    this.emergencyOnes = res.data.result.member.emergencyOne;
                    this.emergencyTwos = res.data.result.member.emergencyTwo;
                    this.formMember.birthday = res.data.result.member.birthday;
                    this.Sphone = res.data.result.member.sparePhone;
                    if (this.formMember.sparePhone != undefined) {
                      let str = this.formMember.sparePhone;
                      this.formMember.sparePhones = str.replace(
                        /(\d{3})\d{4}(\d{1})/,
                        "$1****$2"
                      );
                    }
                    // this.formMember = row.memberInfo;
                    this.formMemberInfo = res.data.result.memberInfo;
                    this.formMemberInfo.live = res.data.result.memberInfo.live;
                    this.formMemberInfo.intellect =
                      res.data.result.memberInfo.intellect;
                    this.formMemberInfo.character =
                      res.data.result.memberInfo.character;
                    this.formMemberInfo.language =
                      res.data.result.memberInfo.language;
                    this.formMemberInfo.hearing =
                      res.data.result.memberInfo.hearing;
                    this.formMemberInfo.vision =
                      res.data.result.memberInfo.vision;
                    this.formMemberInfo.action =
                      res.data.result.memberInfo.action;
                    this.formMemberInfo.illness =
                      res.data.result.memberInfo.illness;
                    this.formMember.createDate =
                      res.data.result.memberInfo.createDate;
                    this.formMember.updateDate =
                      res.data.result.memberInfo.updateDate;
                    this.forBody = res.data.result.memberInfo;
                    this.cityName = res.data.result.member.city;
                    this.countyName = res.data.result.member.county; //TextToCode
                    this.countyId = res.data.result.member.county;
                    this.cityId = res.data.result.member.city;
                    this.formMember.city = CodeToText[this.cityName];
                    this.formMember.county = CodeToText[this.countyName];
                    console.log(this.familyIds, "家庭id");
                    this.ursents = [];
                    this.ursentsTwo = [];
                    apiShout
                      .getFamilyUsers({ fId: this.familyIds })
                      .then((res) => {
                        if (res.data.code === 200) {
                          console.log(res, "紧急联系人");
                          // this.ursents = res.data.result
                          res.data.result.forEach((item) => {
                            this.ursents.push(item);
                            this.ursentsTwo.push(item);
                          });
                          for (var i = 0; i < this.ursents.length; i++) {
                            this.userPhone = this.ursents[i];
                            if (this.userPhone.id === this.emergencyOnes) {
                              this.formMember.emergencyContactOnePhone =
                                this.userPhone.phone;
                              this.formMember.emergencyContactOne =
                                this.userPhone.userName;
                              let str =
                                this.formMember.emergencyContactOnePhone;
                              this.formMember.emergencyContactOnePhone =
                                str.replace(/(\d{3})\d{4}(\d{1})/, "$1****$2");
                            }
                          }
                          for (let i = 0; i < this.ursentsTwo.length; i++) {
                            this.userPhones = this.ursentsTwo[i];
                            if (this.userPhones.id === this.emergencyTwos) {
                              this.formMember.emergencyContactTwoPhone =
                                this.userPhones.phone;
                              this.formMember.emergencyContactTwo =
                                this.userPhones.userName;
                              let str =
                                this.formMember.emergencyContactTwoPhone;
                              this.formMember.emergencyContactTwoPhone =
                                str.replace(/(\d{3})\d{4}(\d{1})/, "$1****$2");
                            }
                          }
                        } else {
                          this.$message.error(res.data.msg);
                        }
                      });
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
                console.log(res, "res保存用户信息信息");
              } else {
                this.$message.error(res.data.msg);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消保存",
          });
        });
    },
    //市
    provinceOne(val) {
      this.ids = val;
      api.AreaCity({ parentId: this.ids }).then((res) => {
        console.log(res);
        // this.historyOrderCity = res.data.result;
        this.historyOrderCitys = res.data.result; //渲染省
      });
    },
    //区
    provinceTwo(val) {
      this.ids = val;
      this.cityId = val;
      api.AreaCity({ parentId: this.ids }).then((res) => {
        // this.historyOrderCity = res.data.result;
        this.historycounty = res.data.result; //渲染省
      });
    },
    Fcounty(val) {
      this.countyId = val;
    },
    //通话记录
    rowBtn(row, column, event) {
      this.isactive = false;
      // let phoneName = JSON.parse(localStorage.getItem("phonex"));
      // let hhName = JSON.parse(localStorage.getItem("hhName"));
      // console.log(phoneName, "123123输出");
      localStorage.setItem("queryPhones", JSON.stringify(row.customerNumber));
      if (this.textPhone == null && this.mobilePhone == null) {
        const audioElement = this.audioElement;
        if (audioElement && audioElement.length > 0) {
          for (let i = 0; i < audioElement.length; i++) {
            this.audioElement[i].pause();
          }
        }
        console.log("点击表格每一行：", row, column, event);
        this.mobilePhone = row.customerNumber;
        this.queryPhone = row.customerNumber;
        this.workOrder.newPhone = row.newPhone;
        this.workOrder.callBackPhoneOne = row.customerNumber;
        localStorage.setItem("UniqueIdnFour", JSON.stringify(row.mainUniqueId));
        if (this.workOrder.callBackPhoneOne != null) {
          this.imgIcon = true;
          this.imgIcons = false;
        } else {
          this.imgIcons = true;
          this.imgIcon = false;
        }
        this.getHistoryOrderUser();
        apiShout
          .getOuterMemberPhones({ phone: row.customerNumber })
          .then((res) => {
            console.log(res, "来电显示用户信息");
            this.dataResult = res.data.result;
            if (res.data.code === 200) {
              // console.log("9679:", res.data.result);
              if (res.data.result == undefined) {
                this.workOrder.phone = row.customerNumber;
                this.workOrder.fixedPhone = row.customerNumber;
                this.workOrder.fixedPhone = this.workOrder.fixedPhone.replace(
                  /(\d{3})\d{4}(\d{1})/,
                  "$1****$2"
                );
              }
              this.workOrder.customerName = res.data.result.name;
              this.cityNames = res.data.result.city;
              this.provinceName = res.data.result.province;
              this.countysId = res.data.result.province;
              this.citysId = res.data.result.city;
              // this.mobilePhone = res.data.result.phone;
              this.workOrder.phone = res.data.result.phone;
              let str = this.workOrder.phone;
              this.workOrder.fixedPhone = str.replace(
                /(\d{3})\d{4}(\d{1})/,
                "$1****$2"
              );
              // this.workOrder.fixedPhone =
              //   this.workOrder.fixedPhone.substr(0, 3) +
              //   "****" +
              //   this.workOrder.fixedPhone.substr(7);
              this.workOrder.sex = res.data.result.sex;
              this.workOrder.city = CodeToText[this.cityNames];
              this.workOrder.province = CodeToText[this.provinceName];
            } else {
              this.workOrder.customerName = "";
              (this.workOrder.province = ""),
                (this.workOrder.city = ""),
                (this.workOrder.callBackPhoneOne = ""),
                (this.mobilePhone = "");
              this.workOrder.fixedPhone = "";
              this.workOrder.sex = "";
              this.$message.error(res.data.msg);
            }
          });
      } else if (
        this.textPhone == null &&
        this.isListion == true &&
        this.mobilePhone != null
      ) {
        const audioElement = this.audioElement;
        if (audioElement && audioElement.length > 0) {
          for (let i = 0; i < audioElement.length; i++) {
            this.audioElement[i].pause();
          }
        }
        console.log("点击表格每一行：", row, column, event);
        this.mobilePhone = row.customerNumber;
        this.queryPhone = row.customerNumber;
        this.workOrder.newPhone = row.newPhone;
        this.workOrder.callBackPhoneOne = row.customerNumber;
        localStorage.setItem("UniqueIdnFour", JSON.stringify(row.mainUniqueId));
        if (this.workOrder.callBackPhoneOne != null) {
          this.imgIcon = true;
          this.imgIcons = false;
        } else {
          this.imgIcons = true;
          this.imgIcon = false;
        }
        this.getHistoryOrderUser();
        apiShout
          .getOuterMemberPhones({ phone: row.customerNumber })
          .then((res) => {
            console.log(res, "来电显示用户信息");
            this.dataResult = res.data.result;
            if (res.data.code === 200) {
              if (res.data.result == undefined) {
                this.workOrder.phone = row.customerNumber;
                this.workOrder.fixedPhone = row.customerNumber;
                this.workOrder.fixedPhone = this.workOrder.fixedPhone.replace(
                  /(\d{3})\d{4}(\d{1})/,
                  "$1****$2"
                );
                this.workOrder.customerName = "";
                (this.workOrder.province = ""),
                  (this.workOrder.city = ""),
                  (this.workOrder.callBackPhoneOne = ""),
                  (this.workOrder.sex = "");
              }
              this.workOrder.customerName = res.data.result.name;
              this.cityNames = res.data.result.city;
              this.provinceName = res.data.result.province;
              this.countysId = res.data.result.province;
              this.citysId = res.data.result.city;
              // this.mobilePhone = res.data.result.phone;
              this.workOrder.phone = res.data.result.phone;
              let str = this.workOrder.phone;
              this.workOrder.fixedPhone = str.replace(
                /(\d{3})\d{4}(\d{1})/,
                "$1****$2"
              );
              // this.workOrder.phone =
              //   this.workOrder.phone.substr(0, 3) +
              //   "****" +
              //   this.workOrder.phone.substr(7);
              this.workOrder.sex = res.data.result.sex;
              this.workOrder.city = CodeToText[this.cityNames];
              this.workOrder.province = CodeToText[this.provinceName];
            } else {
              this.workOrder.customerName = "";
              (this.workOrder.province = ""),
                (this.workOrder.city = ""),
                (this.workOrder.callBackPhoneOne = ""),
                (this.mobilePhone = "");
              this.workOrder.fixedPhone = "";
              this.workOrder.sex = "";
              this.$message.error(res.data.msg);
            }
          });
      } else if (this.textPhone != null && this.mobilePhone != null) {
        event.isTrusted = false;
      }
      // this.workOrder.workNumber = row.customerNumber;
    },
    sphonesOne(tel) {
      this.Sphone = tel;
    },
    //点击表格的每一行事件
    rowClicks(row, column, event) {
      this.$refs.taPopoverOne.doClose();
      console.log(row, "row123");
      console.log(column, "column123");
      console.log(event, "event123");
      this.idList = row.id;
      this.familyIds = row.familyId;
      apiShout.getMemberInfoByIds({ id: row.id }).then((res) => {
        if (res.data.code === 200) {
          //对于生日的处理
          let a = res.data.result.member.birthday;
          let dd = new Date(a);
          let y = dd.getFullYear();
          let m = dd.getMonth() + 1;
          m = m < 10 ? "0" + m : m;
          let d = dd.getDate();
          d = d < 10 ? "0" + d : d;
          res.data.result.member.birthday = y + "-" + m + "-" + d;
          console.log(res, "查询用户信息");
          this.formMember = res.data.result.member;
          this.emergencyOnes = res.data.result.member.emergencyOne;
          this.emergencyTwos = res.data.result.member.emergencyTwo;
          this.formMember.birthday = res.data.result.member.birthday;
          this.Sphone = res.data.result.member.sparePhone;
          if (this.formMember.sparePhone != undefined) {
            let str = this.formMember.sparePhone;
            this.formMember.sparePhones = str.replace(
              /(\d{3})\d{4}(\d{1})/,
              "$1****$2"
            );
          }
          // this.formMember = row.memberInfo;
          this.formMemberInfo = res.data.result.memberInfo;
          this.formMemberInfo.live = res.data.result.memberInfo.live;
          this.formMemberInfo.intellect = res.data.result.memberInfo.intellect;
          this.formMemberInfo.character = res.data.result.memberInfo.character;
          this.formMemberInfo.language = res.data.result.memberInfo.language;
          this.formMemberInfo.hearing = res.data.result.memberInfo.hearing;
          this.formMemberInfo.vision = res.data.result.memberInfo.vision;
          this.formMemberInfo.action = res.data.result.memberInfo.action;
          this.formMemberInfo.illness = res.data.result.memberInfo.illness;
          this.formMember.createDate = res.data.result.memberInfo.createDate;
          this.formMember.updateDate = res.data.result.memberInfo.updateDate;
          this.forBody = res.data.result.memberInfo;
          this.cityName = res.data.result.member.city;
          this.countyName = res.data.result.member.county; //TextToCode
          this.countyId = res.data.result.member.county;
          this.cityId = res.data.result.member.city;
          this.formMember.city = CodeToText[this.cityName];
          this.formMember.county = CodeToText[this.countyName];
          console.log(this.familyIds, "家庭id");
          this.ursents = [];
          this.ursentsTwo = [];
          apiShout.getFamilyUsers({ fId: this.familyIds }).then((res) => {
            if (res.data.code === 200) {
              console.log(res, "紧急联系人");
              // this.ursents = res.data.result
              res.data.result.forEach((item) => {
                this.ursents.push(item);
                this.ursentsTwo.push(item);
              });
              for (var i = 0; i < this.ursents.length; i++) {
                this.userPhone = this.ursents[i];
                if (this.userPhone.id === this.emergencyOnes) {
                  this.formMember.emergencyContactOnePhone =
                    this.userPhone.phone;
                  this.formMember.emergencyContactOne = this.userPhone.userName;
                  let str = this.formMember.emergencyContactOnePhone;
                  this.formMember.emergencyContactOnePhone = str.replace(
                    /(\d{3})\d{4}(\d{1})/,
                    "$1****$2"
                  );
                }
              }
              for (let i = 0; i < this.ursentsTwo.length; i++) {
                this.userPhones = this.ursentsTwo[i];
                if (this.userPhones.id === this.emergencyTwos) {
                  this.formMember.emergencyContactTwoPhone =
                    this.userPhones.phone;
                  this.formMember.emergencyContactTwo =
                    this.userPhones.userName;
                  let str = this.formMember.emergencyContactTwoPhone;
                  this.formMember.emergencyContactTwoPhone = str.replace(
                    /(\d{3})\d{4}(\d{1})/,
                    "$1****$2"
                  );
                }
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
      if (this.textPhone != null) {
        event.isTrusted = false;
      }
      // else{
      //   event.isTrusted = true;
      // }
    },
    //点击表格的每一行事件
    // rowClick(row, column, event) {
    //   console.log(row, "row123");
    //   console.log(column, "column123");
    //   console.log(event, "event123");
    //   this.workOrder.phone = row.phone;
    //   this.workOrder.workNumber = row.phone;
    //   this.customerIds = row.customerId;
    //   this.equipmentIds = row.equipmentId;
    //   if (this.isListioning == false) {
    //     this.prohibit = event.cancelable;
    //     if (row.equipmentId == null) {
    //       this.formMember = {};
    //       this.formMembers = {};
    //       this.forBody = {};
    //       this.formMember.birthday = "";
    //       this.valueLive = "";
    //       this.valuezhiLi = "";
    //       this.valuexingge = "";
    //       this.valueyuyan = "";
    //       this.valuetingli = "";
    //       this.valueshili = "";
    //       this.valuexingdong = "";
    //       this.valuemanxing = "";
    //       this.county = "";
    //       this.city = "";
    //       this.historyList = [];
    //     } else {
    //       apiShout.getMemberInfoByIds({ id: row.id }).then((res) => {
    //         console.log(res, "查询用户信息");
    //         this.formMember = res.data.result.member;
    //         this.cityName = res.data.result.member.city;
    //         this.countyName = res.data.result.member.county;
    //         this.city = CodeToText[this.cityName];
    //         this.county = CodeToText[this.countyName];
    //         // this.formMember = row.memberInfo;
    //         this.formMember.birthday = res.data.result.member.birthday;
    //         this.valueLive = res.data.result.memberInfo.live;
    //         this.valuezhiLi = res.data.result.memberInfo.intellect;
    //         this.valuexingge = res.data.result.memberInfo.character;
    //         this.valueyuyan = res.data.result.memberInfo.language;
    //         this.valuetingli = res.data.result.memberInfo.hearing;
    //         this.valueshili = res.data.result.memberInfo.vision;
    //         this.valuexingdong = res.data.result.memberInfo.action;
    //         this.valuemanxing = res.data.result.memberInfo.illness;
    //         //健康数据
    //         this.forBody = res.data.result.memberInfo;
    //         for (let i = 0; i < res.data.result.remind.length; i++) {
    //           this.morningbefor = res.data.result.remind[0];
    //           this.morningafter = res.data.result.remind[1];
    //           this.lunchbefor = res.data.result.remind[2];
    //           this.lunchafter = res.data.result.remind[3];
    //           this.eveningbefor = res.data.result.remind[4];
    //         }
    //       });
    //       this.getHistoryOrderUser();
    //     }
    //   } else if (this.isListioning == false && this.isListion == true) {
    //     if (row.equipmentId == null) {
    //       this.formMember = {};
    //       this.formMembers = {};
    //       this.forBody = {};
    //       this.formMember.birthday = "";
    //       this.valueLive = "";
    //       this.valuezhiLi = "";
    //       this.valuexingge = "";
    //       this.valueyuyan = "";
    //       this.valuetingli = "";
    //       this.valueshili = "";
    //       this.valuexingdong = "";
    //       this.valuemanxing = "";
    //       this.county = "";
    //       this.city = "";
    //       this.historyList = [];
    //     } else {
    //       apiShout.getMemberInfoByIds({ id: row.id }).then((res) => {
    //         console.log(res, "查询用户信息");
    //         this.formMember = res.data.result.member;
    //         this.cityName = res.data.result.member.city;
    //         this.countyName = res.data.result.member.county;
    //         this.city = CodeToText[this.cityName];
    //         this.county = CodeToText[this.countyName];
    //         // this.formMember = row.memberInfo;
    //         this.formMember.birthday = res.data.result.member.birthday;
    //         this.valueLive = res.data.result.memberInfo.live;
    //         this.valuezhiLi = res.data.result.memberInfo.intellect;
    //         this.valuexingge = res.data.result.memberInfo.character;
    //         this.valueyuyan = res.data.result.memberInfo.language;
    //         this.valuetingli = res.data.result.memberInfo.hearing;
    //         this.valueshili = res.data.result.memberInfo.vision;
    //         this.valuexingdong = res.data.result.memberInfo.action;
    //         this.valuemanxing = res.data.result.memberInfo.illness;
    //         //健康数据
    //         this.forBody = res.data.result.memberInfo;
    //         for (let i = 0; i < res.data.result.remind.length; i++) {
    //           this.morningbefor = res.data.result.remind[0];
    //           this.morningafter = res.data.result.remind[1];
    //           this.lunchbefor = res.data.result.remind[2];
    //           this.lunchafter = res.data.result.remind[3];
    //           this.eveningbefor = res.data.result.remind[4];
    //         }
    //       });
    //       this.getHistoryOrderUser();
    //     }
    //   } else if (this.isListioning == true) {
    //     event.isTrusted = false;
    //   }
    // },
    valuebases(val) {
      console.log(val, "sousuo");
      val === this.listvalue;
    },
    //查询用户信息
    searchList() {
      if (this.valuebase == "1") {
        this.formDate.name = this.inputsize;
      } else {
        this.formDate.name = "";
      }
      if (this.valuebase == "2") {
        this.formDate.sparePhone = this.inputsize;
      } else {
        this.formDate.sparePhone = "";
      }
      if (this.valuebase == "3") {
        this.formDate.equipmentNo = this.inputsize;
      } else {
        this.formDate.equipmentNo = "";
      }
      if (this.valuebase == "4") {
        this.formDate.emergencyContact = this.inputsize;
      } else {
        this.formDate.emergencyContact = "";
      }
      if (this.valuebase == "5") {
        this.formDate.emergencyContactPhone = this.inputsize;
      } else {
        this.formDate.emergencyContactPhone = "";
      }
      if (this.inputsize != "" && this.valuebase != "") {
        apiShout
          .getMemberInfos({
            name: this.formDate.name,
            sparePhone: this.formDate.sparePhone,
            equipmentNo: this.formDate.equipmentNo,
            emergencyContact: this.formDate.emergencyContact,
            emergencyContactPhone: this.formDate.emergencyContactPhone,
          })
          .then((res) => {
            if (res.data.code === 200) {
              console.log(res, "查询用户信息");
              this.gridData = res.data.result;
              this.gridData.forEach((item) => {
                if (item.sparePhone != null) {
                  //对于电话的处理
                  item.sparePhone =
                    item.sparePhone.substr(0, 3) +
                    "****" +
                    item.sparePhone.substr(7);
                }
              });
            } else {
              this.$message.error(res.data.msg);
            }
          });
      } else {
        return false;
      }
    },
    //待我办理的完成
    handleEditDeal(index, row) {
      console.log("待我办理的完成：", index, row);
      this.dialogVisibleFinish = true;
      let obj = {
        status: "1",
        id: row.id,
      };
      this.quFinish = { ...obj };
      // apiShout.getUpdateHotOrder(obj).then((res) => {
      //   console.log("待我办理的完成：", res.data);
      //   if (res.data.code == 200) {
      //     //刷新待我办理
      //     this.getFourCommissions();
      //     //刷新历史工单
      //     this.getHistoryOrderUser();
      //     //全部待办
      //     this.allGetFourCommission();
      //     this.$message({
      //       message: "恭喜你，更新工单成功",
      //       type: "success",
      //     });
      //   }
      // });
    },
    //改派人员弹框
    cellClick1(row) {
      //console.log('===========',row);
      this.gaipei = row.id;
    },
    //全部待办的改派
    handleEditChange(index, row) {
      console.log("--------------", row.id);
      this.dialogVisible1 = true;
      this.quitedId = row.id;
    },
    quietChange() {
      this.dialogVisible1 = false;
      let obj = {
        userId: this.gaipei,
        status: "0",
        id: this.quitedId,
      };
      apiShout.getUpdateHotOrder(obj).then((res) => {
        console.log("改派工单：", res.data);
        if (res.data.code == 200) {
          //刷新全部待办工单
          this.allGetFourCommission();
          //刷新待我办理工单
          this.getFourCommissions();
          this.$message({
            message: "恭喜你，改派工单成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //查询代我办理
    getFourCommissions() {
      //待我办理
      apiShout.fourCommissions({ userId: this.userId }).then((res) => {
        console.log("待我办理：", res.data);
        if (res.data.code === 200) {
          //对于剩余时间的处理
          this.remaining = res.data.result.data;
          this.remaining.forEach((element) => {
            console.log("====:", element.remainingTime);
            if (element.remainingTime == "0" || element.remainingTime == "") {
              element.remainingTime = "已到期";
            } else if (element.remainingTime != null) {
              element.remainingTime =
                Math.floor(element.remainingTime / 60) +
                "小时" +
                (element.remainingTime % 60) +
                "分";
            }
            // alert(element.remainingTime)
            // if (element.handleTime != null) {
            //   element.handleTime = element.handleTime + "小时";
            // } else {
            //   element.handleTime = "已到期";
            // }
          });
          // for (let j = 0; j < res.data.result.data.length; j++) {
          //   if (res.data.result.data[j].remainingTime == null) {
          //     res.data.result.data[j].remainingTime = "已到期";
          //   } else if (res.data.result.data[j].remainingTime != null) {
          //     res.data.result.data[j].remainingTime =
          //       res.data.result.data[j].remainingTime + "小时";
          //   }
          // }
          this.tableData = res.data.result.data;
          for (var i = 0; i < this.tableData.length; i++) {
            let str = this.tableData[i].callBackPhone;
            this.tableData[i].newPhone = str.replace(
              /(\d{3})\d{4}(\d{1})/,
              "$1****$2"
            );
          }
          this.count = res.data.result.data.length;
          console.log(res.data.result.data.length, this.count, "countzhanshi");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //查询用户历史工单
    getHistoryOrderUser() {
      apiShout.getFourHistory({ phone: this.queryPhone }).then((res) => {
        //console.log('400历史工单:',res.data);
        if (res.data.code == 200) {
          //对于日期的处理
          for (let i = 0; i < res.data.result.length; i++) {
            if (res.data.result[i].createDate == null) {
              res.data.result[i].createDate = "";
            } else {
              let a = res.data.result[i].createDate;
              let ds = new Date(a);
              let y = ds.getFullYear();
              let m = ds.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = ds.getDate();
              d = d < 10 ? "0" + d : d;
              let h = ds.getHours();
              let minute = ds.getMinutes();
              minute = minute < 10 ? "0" + minute : minute;
              let second = ds.getSeconds();
              second = second < 10 ? "0" + second : second;
              res.data.result[i].createDate =
                y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
            }
          }
          this.historyList = res.data.result;
        } else {
          this.$message.error(res.data.msg);
        }
      });
      // //查询用户信息
      // apiShout.getuserInfor({ phone: "13401107330" }).then((dat) => {
      //   //console.log('用户信息：',dat.data);
      //   if (dat.data.code === 200) {
      //     //对于生日的处理
      //     let a = dat.data.result.member.birthday;
      //     let dd = new Date(a);
      //     let y = dd.getFullYear();
      //     let m = dd.getMonth() + 1;
      //     m = m < 10 ? "0" + m : m;
      //     let d = dd.getDate();
      //     d = d < 10 ? "0" + d : d;
      //     dat.data.result.member.birthday = y + "-" + m + "-" + d;
      //     //对于激活日期的处理
      //     let b = dat.data.result.member.createDate;
      //     let dd1 = new Date(b);
      //     let y1 = dd1.getFullYear();
      //     let m1 = dd1.getMonth() + 1;
      //     m1 = m1 < 10 ? "0" + m1 : m1;
      //     let d1 = dd1.getDate();
      //     d1 = d1 < 10 ? "0" + d1 : d1;
      //     dat.data.result.member.createDate = y1 + "-" + m1 + "-" + d1;
      //     //对于到期日期的处理
      //     let c = dat.data.result.member.updateDate;
      //     let dd2 = new Date(c);
      //     let y2 = dd2.getFullYear();
      //     let m2 = dd2.getMonth() + 1;
      //     m2 = m2 < 10 ? "0" + m2 : m2;
      //     let d2 = dd2.getDate();
      //     d2 = d2 < 10 ? "0" + d2 : d2;
      //     dat.data.result.member.updateDate = y2 + "-" + m2 + "-" + d2;
      //     this.formMember = dat.data.result.member;
      //     this.formMemberInfo = dat.data.result.memberInfo;
      //     this.cityName = dat.data.result.member.city;
      //     this.countyName = dat.data.result.member.county; //TextToCode
      //     this.countyId = dat.data.result.member.county;
      //     this.cityId = dat.data.result.member.city;
      //     this.formMember.city = CodeToText[this.cityName];
      //     this.formMember.county = CodeToText[this.countyName];
      //     this.formMember.birthday = dat.data.result.member.birthday;
      //     this.cityName = dat.data.result.member.city;
      //     this.countyName = dat.data.result.member.county;
      //     // console.log(CodeToText[this.countyName], "CodeToText123");
      //     // console.log(CodeToText[this.cityName], "CodeToText11111");
      //     // this.formMember.city = CodeToText[this.cityName];
      //     // this.formMember.county = CodeToText[this.countyName];
      //     this.formMemberInfo.live = dat.data.result.memberInfo.live;
      //     this.formMemberInfo.intellect = dat.data.result.memberInfo.intellect;
      //     this.formMemberInfo.character = dat.data.result.memberInfo.character;
      //     this.formMemberInfo.language = dat.data.result.memberInfo.language;
      //     this.formMemberInfo.hearing = dat.data.result.memberInfo.hearing;
      //     this.formMemberInfo.vision = dat.data.result.memberInfo.vision;
      //     this.formMemberInfo.action = dat.data.result.memberInfo.action;
      //     this.formMemberInfo.illness = dat.data.result.memberInfo.illness;
      //     //健康数据
      //     this.forBody = dat.data.result.memberInfo;
      //     for (let i = 0; i < dat.data.result.remind.length; i++) {
      //       this.morningbefor = dat.data.result.remind[0];
      //       this.morningafter = dat.data.result.remind[1];
      //       this.lunchbefor = dat.data.result.remind[2];
      //       this.lunchafter = dat.data.result.remind[3];
      //       this.eveningbefor = dat.data.result.remind[4];
      //     }
      //   } else {
      //     this.$router.push("/");
      //   }
      // });
    },
    //查询全部待办
    allGetFourCommission() {
      apiShout.getFourCommission().then((res) => {
        console.log("全部待办：", res.data);
        if (res.data.code === 200) {
          //对于日期的处理
          for (let i = 0; i < res.data.result.data.length; i++) {
            if (res.data.result.data[i].createDate == null) {
              res.data.result.data[i].createDate = "";
            } else {
              let a = res.data.result.data[i].createDate;
              let dd = new Date(a);
              let y = dd.getFullYear();
              let m = dd.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = dd.getDate();
              d = d < 10 ? "0" + d : d;
              let h = dd.getHours();
              let minute = dd.getMinutes();
              minute = minute < 10 ? "0" + minute : minute;
              let second = dd.getSeconds();
              second = second < 10 ? "0" + second : second;
              // res.data.result.data[i].createDate =
              //   y + "-" + m + "-" + d;
              res.data.result.data[i].createDate =
                y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
            }
          }
          this.remaining = res.data.result.data;
          this.remaining.forEach((element) => {
            // console.log("====:", element.remainingTime);
            if (element.remainingTime == "0" || element.remainingTime == "") {
              element.remainingTime = "已到期";
            } else if (element.remainingTime != null) {
              element.remainingTime =
                Math.floor(element.remainingTime / 60) +
                "小时" +
                (element.remainingTime % 60) +
                "分";
            }
          });
          //对于剩余时间的处理
          // for (let j = 0; j < res.data.result.data.length; j++) {
          //   // res.data.result.data[j].remainingTime =
          //   // res.data.result.data[j].remainingTime + "小时";
          //   if (res.data.result.data[j].remainingTime == null) {
          //     res.data.result.data[j].remainingTime = "已到期";
          //   } else if (res.data.result.data[j].remainingTime != null) {
          //     res.data.result.data[j].remainingTime =
          //       res.data.result.data[j].remainingTime + "小时";
          //   }
          //   // console.log(res.data.result.data[j].remainingTime,"xres.data.result.data[j].remainingTime")
          // }
          this.tableData2 = res.data.result.data;
          console.log(res.data.result.data, "vvvv");
          this.tableData2.forEach((item) => {
            if (item.handleTime == "0") {
              item.handleTime = "不限制";
            } else if (item.handleTime != null) {
              item.handleTime = item.handleTime + "小时";
            } else if (item.handleTime == null) {
              item.handleTime = "已到期";
            }
            if (item.callBackPhone !== null) {
              let str = item.callBackPhone;
              console.log(item, item.callBackPhone, "item.callBackPhone");
              item.newPhone = str.replace(/(\d{3})\d{4}(\d{1})/, "$1****$2");
            }
          });
          this.countAll = res.data.result.data.length;
          console.log(this.countAll, "this.countAll展示");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    tellQuit(tell) {
      if (tell == "") {
        this.$message.error("号码不能为空!");
      } else {
        this.$confirm("手机转接, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }).then(() => {
          console.log(tell, "查看输入的号码");
          apiShout.setTranPhone({ phone: tell }).then((res) => {
            if (res.data.code == 200) {
              console.log(res.data, "查看转接是否成功");
              this.$message({
                type: "success",
                message: "转接成功!",
              });
              this.tellStatus = "手机接听中...";
              this.closeButton = true;
            } else {
              this.$message.error(res.data.msg);
            }
          });
        });
      }
    },
    closeTell() {
      this.dialogVisibleTell = false;
      this.tellStatus = "手机转接";
      this.inputPhsTell = "";
      this.closeButton = false;
    },
    cancelTell(tell) {
      if (tell == "") {
        this.$message.error("号码不能为空!");
      } else {
        this.$confirm("取消转接, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }).then(() => {
          console.log(tell, "查看输入的号码");
          apiShout.cancelTranPhone({ phone: tell }).then((res) => {
            if (res.data.code == 200) {
              console.log(res.data, "查看转接是否成功");
              this.$message({
                type: "success",
                message: "取消转接!",
              });
              this.dialogVisibleTell = false;
              this.tellStatus = "手机转接";
              this.inputPhsTell = "";
              this.closeButton = false;
            } else {
              this.$message.error(res.data.msg);
            }
          });
        });
      }
    },
    myStatusValue(value) {
      console.log(value, "查看value");
      if (value == 2) {
        this.dialogVisibleTell = true;
        console.log("进入这个里面");
      }
    },
    //客服状态的修改
    myStatus(value) {
      // console.log('=====',value);
      //console.log('=====',value);
      this.cnoStatus = value;
      // this.$emit("fourserStatus", value);
      localStorage.setItem("serverStatus", JSON.stringify(value));
      apiShout.getUpStatus({ cno: this.cno, status: value }).then((res) => {
        console.log("修改状态：", res.data);
        if (value == "0" && res.data.code == 200) {
          // this.endReason();
          //空闲
          clearInterval(this.timer4);
          this.currentTime2 = 0;
          this.testTimer2();
          // this.timer3 = setInterval(this.shout, 3000);
          // this.shout();
        } else if (value == "1" && res.data.code == 200) {
          //忙碌
          clearInterval(this.timer4);
          this.currentTime2 = 0;
          this.testTimer2();
          // clearInterval(this.timer3);
        } else if (value == "2" && res.data.code == 200) {
          //暂离
          clearInterval(this.timer4);
          this.currentTime2 = 0;
          this.testTimer2();
          // clearInterval(this.timer3);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    Discoloration(index) {
      console.log(index, "index");
    },
    //省
    provinces(val) {
      this.ids = val;
      this.countysId = val;
      api.getCitys({ parentId: val }).then((res) => {
        this.historyOrderCity = res.data.result; //渲染市
        // this.historyProvince = res.data.result;
      });
    },
    citysName(val) {
      this.citysId = val;
    },
    //模糊搜索
    handlChange(val) {
      if (this.searchs !== "") {
        this.dataLists = "";
        //获取常用知识
        apiShout.getKnowledge({ text: val }).then((res) => {
          //console.log("常用知识：", res.data);
          if (res.data.code === 200) {
            this.dataLists = res.data.result[0].childs[0].text;
          } else {
            this.$message.error(res.data.msg);
          }
        });
        console.log(apiShout.getKnowledge(), "清空搜索框展示数据");
      } else {
        this.offenKnow();
      }
    },
    // 模糊搜索 点击事件
    reset() {
      if (!this.searchs) {
        this.$message.error("请输入查询条件");
      } else {
        this.dim();
      }
    },

    // 查看接口 === 搜索接口
    dim() {
      let val = {
        text: this.searchs, //把搜索框的值传给后端
      };
      apiShout.getSearch(val).then((res) => {
        if (res.data.code === 200) {
          // this.searchs=res.data;
          // for(let i=0; i<res.data.result.length; i++){
          //   this.dataList = res.data.result[i].type; //与查看接口一致 这步是不能少的
          //   console.log(this.dataList,"shuchushuchu")
          // }
          // console.log(res.data.result, "搜索");
          this.dataLists = res.data.result; //与查看接口一致 这步是不能少的
          this.dataList = res.data.result; //与查看接口一致 这步是不能少的
          //如果有分页,调一下分页方法
          // console.log(this.dataList, "模糊搜索================");
          // console.log(this.dataLists.length);
          if (this.dataLists.length > 0) {
            for (var i = 0; i < this.dataLists.length; i++) {
              console.log(this.dataLists[i].type);
              console.log(this.dataLists[i].childs);
              for (var j = 0; j < this.dataLists[i].childs.length; j++) {
                console.log(this.dataLists[i].childs[j].text);
                this.dataLists = this.dataLists[i].childs[0].text;
                this.dataList = this.dataLists[i].childs[0].title;
              }
            }
          } else {
            this.dataLists = "";
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // getCurTime(){
    //   var audios = document.getElementById("myAudio");
    //   // alert(audios.currentTime)
    //   this.videos = audios.currentTime
    // },
    handlePlay(index) {
      this.indexOfs = index;
      // let phoneName = JSON.parse(localStorage.getItem("phonex"));
      // let hhName = JSON.parse(localStorage.getItem("hhName"));
      // console.log(phoneName, "123123输出");
      // console.log(hhName, "123123输出hhName");
      if (this.textPhone == null && this.mobilePhone == null) {
        const audioElement = this.audioElement;
        if (audioElement && audioElement.length > 0) {
          for (let i = 0; i < audioElement.length; i++) {
            if (i === index) {
              this.audioElement[i].play();
            } else {
              this.audioElement[i].pause();
            }
          }
        }
      } else if (
        this.textPhone != null &&
        this.isListion == true &&
        this.mobilePhone != null
      ) {
        const audioElement = this.audioElement;
        if (audioElement && audioElement.length > 0) {
          for (let i = 0; i < audioElement.length; i++) {
            if (i === index) {
              this.audioElement[i].play();
            } else {
              this.audioElement[i].pause();
            }
          }
        }
      } else if (this.textPhone != null && this.mobilePhone != null) {
        const audioElement = this.audioElement;
        if (audioElement && audioElement.length > 0) {
          for (let i = 0; i < audioElement.length; i++) {
            this.audioElement[i].pause();
          }
        }
      }
      // else {
      //   const audioElement = this.audioElement;
      //   if (audioElement && audioElement.length > 0) {
      //     for (let i = 0; i < audioElement.length; i++) {
      //       this.audioElement[i].pause();
      //     }
      //   }
      // }
    },
    //创建工单的提交
    finish() {
      //创建工
      if (
        this.workOrder.fixedPhone == "" ||
        this.workOrder.fixedPhone == null
      ) {
        this.$message.error("请获取用户信息！");
        return;
      }
      if (
        this.workOrder.businessType == "" ||
        this.workOrder.businessType == null
      ) {
        this.$message.error("业务分类不能为空！");
        return;
      }
      if (this.workOrder.describe == "" || this.workOrder.describe == null) {
        this.$message.error("工单描述内容不能为空！");
        return;
      }
      if (
        this.workOrder.callBackPhone == "" ||
        this.workOrder.callBackPhone == null
      ) {
        this.workOrder.callBackPhone = this.workOrder.phone;
      }
      let fourUniq = JSON.parse(localStorage.getItem("UniqueIdnFour"));
      this.workOrder.mainUniqueId = fourUniq;
      this.workOrder.province = this.countysId;
      this.workOrder.city = this.citysId;
      this.workOrder.fixedPhone = this.workOrder.phone;
      this.workOrder.callBackPhoneOne = this.workOrder.callBackPhone;
      this.workOrder.calltype = this.calltypes;
      this.workOrder.serviceCalltype = this.calltypes;
      apiShout.getCreateFourOrder(this.workOrder).then((aa) => {
        console.log("创建工单：", aa.data);
        if (aa.data.code === 200) {
          //清空选中的选项
          // this.workOrder.customerName = "";
          // this.workOrder.sex = "";
          // this.workOrder.fixedPhone = "";
          // this.workOrder.province = "";
          // this.workOrder.city = "";
          // this.workOrder.businessType = [];
          this.workOrder.describe = "";
          this.workOrder.handleTime = "";
          this.workOrder.priority = "";
          this.workOrder.callback = null;
          this.workOrder.callBackPhoneOne = "";
          this.workOrder.callBackPhone = "";
          this.CallbackInformation = false;
          this.workOrder.city = CodeToText[this.workOrder.city];
          this.workOrder.province = CodeToText[this.workOrder.province];
          //页面刷新 全部待办  待我办理
          // this.reload();
          this.getFourCommissions();
          //全部待办
          this.allGetFourCommission();
          console.log(this.queryPhone, "查询电话号码");
          // let namePhone = JSON.parse(localStorage.getItem("queryPhones"));
          // this.queryPhone = namePhone;
          this.getHistoryOrderUser();
          this.$message({
            type: "success",
            message: "提交成功!",
          });

          // this.getFourCommissions();
        } else {
          this.$message.error(aa.data.msg);
        }
        // this.getFourCommissions();
      });
      let obj = {
        name: this.workOrder.customerName,
        city: this.citysId,
        province: this.countysId,
        phone: this.workOrder.phone,
        sex: this.workOrder.sex,
      };
      if (this.dataResult == "" || this.dataResult == null) {
        apiShout.insertOuterMembers(obj).then((res) => {
          console.log(res, "提交新增用户信息");
          if (res.data.code === 200) {
            //清空选中的选项
            // this.workOrder.customerName = "";
            // this.workOrder.sex = "";
            // this.workOrder.fixedPhone = "";
            // this.workOrder.province = "";
            // this.workOrder.city = "";
            this.$message({
              type: "success",
              message: " 添加成功!",
            });
            // this.workOrder.city = CodeToText[this.citysId];
            // this.workOrder.province = CodeToText[this.countysId];
          } else {
            this.$message.error(res.data.msg);
          }
        });
        // this.getFourCommissions();
      } else if (this.dataResult != "" || this.dataResult != null) {
        apiShout.updateOuterMembers(obj).then((res) => {
          if (res.data.code === 200) {
            //清空选中的选项
            // this.workOrder.customerName = "";
            // this.workOrder.sex = "";
            // this.workOrder.fixedPhone = "";
            // this.workOrder.province = "";
            // this.workOrder.city = "";
            // this.$message.error(res.data.msg);
            // this.workOrder.city = CodeToText[this.citysId];
            // this.workOrder.province = CodeToText[this.countysId];
          }
        });
      }

      // apiShout.getCreateFourOrder(this.workOrder).then((res) => {
      //   console.log(res.data, "提交");
      // });
    },
    //是否分配有来电呼叫接口
    shout() {
      // this.endReason();
      // localStorage.setItem("phonex", JSON.stringify(this.textPhone));
      if (this.isJIN == true) {
        this.muteCount = 0;
        this.isYIN = true;
        this.isJIN = false;
      }
      if (this.mscode == 111 || this.mscode == "111") {
        //铃响
        clearInterval(this.timer);
        this.$refs.audioLing.pause();
        this.isListion = true;
        this.isListioning = false;
        this.cnoStatus = 1;
        if (this.isAnswer == true) {
          this.isAnswer = false;
        }
        clearInterval(this.timer4);
        this.currentTime2 = 0;
        this.testTimer2();
        console.log("取消铃声，走到这一步");
        return false;
      } else {
        if (this.cnoStatus != "0" || this.cnoStatus != 0) {
          return false;
        } else {
          if (this.textPhone != null) {
            const audioElement = this.audioElement;
            if (audioElement && audioElement.length > 0) {
              for (let i = 0; i < audioElement.length; i++) {
                this.audioElement[i].pause();
              }
            }
          }
          if (this.textPhone == null) {
            clearInterval(this.timer);
            clearInterval(this.timer2);
            this.currentTime1 = 0;
            clearInterval(this.timer1);
            this.currentTime = 0;
            //隐藏最上边的按钮
            this.isAnswer = false;
            this.iconImg = false;
          } else if (this.textPhone != null) {
            this.workOrder.fixedPhone = this.textPhone;
            this.workOrder.phone = this.textPhone;
            console.log("来电时用户电话：", this.workOrder.phone);
            let str = this.workOrder.fixedPhone;
            this.workOrder.fixedPhone = str.replace(
              /(\d{3})\d{4}(\d{1})/,
              "$1****$2"
            );
            this.mobilePhone = this.textPhone;
            this.queryPhone = this.textPhone;
            localStorage.setItem(
              "queryPhones",
              JSON.stringify(this.queryPhone)
            );
            localStorage.setItem("phoneNumber", JSON.stringify(this.textPhone));
            console.log(this.textPhone, this.mobilePhone, "123123");
            // this.workOrder.callBackPhoneOne = res.data.result.phone;
            // let str2 = this.workOrder.callBackPhoneOne;
            // this.workOrder.callBackPhoneOne = str2.replace(
            //   /(\d{3})\d{4}(\d{1})/,
            //   "$1****$2"
            // );
            // this.workOrder.callBackPhone = res.data.result.phone;
            // let str3 = this.workOrder.callBackPhone;
            // this.workOrder.callBackPhone = str3.replace(
            //   /(\d{3})\d{4}(\d{1})/,
            //   "$1****$2"
            // );
            this.iconImg = false;
            // clearInterval(this.timer3);
            //显示最上边的按钮
            this.isAnswer = true;
            clearInterval(this.timer);
            clearInterval(this.timer1);
            this.currentTime = 0;
            this.currentTime1 = 0;
            //铃响
            this.startRotate();
            this.$refs.audioLing.play();
            this.$refs.audioLing.muted = false;
            //开始计时器
            this.testTimer();
            //存储本地
            localStorage.setItem("phone", JSON.stringify(this.textPhone));
            this.getHistoryOrderUser();
            apiShout
              .getOuterMemberPhones({ phone: this.textPhone })
              .then((res) => {
                console.log(res.data, "来电显示用户信息");
                this.dataResult = res.data.result;
                if (res.data.code === 200) {
                  this.workOrder.customerName = res.data.result.name;
                  this.cityNames = res.data.result.city;
                  this.provinceName = res.data.result.province;
                  this.localNumber = res.data.result.phone;
                  this.workOrder.Newphone = res.data.result.phone;
                  let str = this.workOrder.Newphone;
                  this.workOrder.Newphone = str.replace(
                    /(\d{3})\d{4}(\d{1})/,
                    "$1****$2"
                  );
                  // this.mobilePhone = res.data.result.phone;
                  this.workOrder.phone = res.data.result.phone;
                  console.log("来电时用户电话：", this.workOrder.phone);
                  // this.callBackPhoneFirst =res.data.result.phone;
                  this.workOrder.sex = res.data.result.sex;
                  this.workOrder.city = CodeToText[this.cityNames];
                  this.workOrder.province = CodeToText[this.provinceName];
                  this.countysId = res.data.result.province;
                  this.citysId = res.data.result.city;
                } else {
                  this.$message.error(res.data.msg);
                }
              });
            if (this.mobilePhone != null) {
              this.imgIcon = true;
              this.imgIcons = false;
            } else {
              this.imgIcons = true;
              this.imgIcon = false;
            }

            //请求挂机接口
            // this.timer5 = setInterval(this.endReason, 1000);
          }
        }
      }
    },
    //点击接听事件
    listion() {
      if (this.cnoStatus != "0" || this.cnoStatus != 0) {
        return false;
      } else {
        if (this.seType == 1) {
          this.$emit("changeEvent2", "1");
          this.isFour = false;
          this.isServer = false;
          this.isServer1 = false;
          clearInterval(this.timer4);
          clearInterval(this.timer);
          this.$refs.audioLing.pause();
          this.currentTime2 = 0;
          this.testTimer2();
          this.isAnswer = false;
          return false;
        }
        let dk = JSON.parse(localStorage.getItem("listionPhone"));
        this.textPhone = dk;
        this.xianPhone = dk;
        this.xianPhone =
          this.xianPhone.substr(0, 3) + "****" + this.xianPhone.substr(7);
        this.shout();
        this.hereLiS = true;
        this.$emit("fourHere", this.hereLiS);
        this.isFour = false;
        this.isServer = false;
        this.isServer1 = false;
        var objss = JSON.stringify({
          cno: this.cno,
          msgcode: 204,
          token: this.loginToken,
          phone: this.textPhone || "",
        });
        this.websocketsend(objss);
        // var objs = JSON.stringify({
        //   cno: this.cno,
        //   msgcode: 210,
        //   token: this.loginToken,
        //   phone: this.textPhone || "",
        //   userid: 0,
        //   equipmentid: null,
        //   calltype: "1",
        // });
        // this.websocketsend(objs);
        this.calltypes = "0";
        //铃不动不响
        clearInterval(this.timer);
        this.$refs.audioLing.pause();
        //显示远程协助和生活服务
        this.isLive = false;
        this.isHelp = false;
        //记时结束
        clearInterval(this.timer1);
        this.currentTime = 0;
        this.Personstatus = "呼叫中";
        //切换上边
        this.isListion = false;
        this.isListioning = true;
        if (this.isListioning == true) {
          this.iconImg = false;
          this.iconImgs = false;
        }
        //客服状态置忙
        this.valuezhuangtai = "忙碌";
        this.cnoStatus = 1;
        localStorage.setItem("serverStatus", JSON.stringify(this.cnoStatus));
        //客服状态置忙下的计时器
        clearInterval(this.timer4);
        this.currentTime2 = 0;
        this.testTimer2();

        //记时通话时间
        this.testTimer1();
        // //将客服状态置忙
        // apiShout.getBusy({ cno: this.cno }).then((aa) => {
        //   console.log("客服置忙：", aa.data);
        // });
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // ClinkAgent.registerCallback(ClinkAgent.ResponseType.SIP_LINK, function(event) {
          //   console.log('软电话接听-----------0：',event);
          // }); // 软电话接听
          ClinkAgent.sipLink();
        });
        const audioElement = this.audioElement;
        if (audioElement && audioElement.length > 0) {
          for (let i = 0; i < audioElement.length; i++) {
            this.audioElement[i].pause();
          }
        }
        this.dataPhone = "1";
        this.statusOne = "0";
        this.resetForm();
        localStorage.setItem("crossHere", JSON.stringify(false));
      }
    },
    //挂断电话
    offPhone() {
      console.log(this.textPhone, this.phoneOne, "1231231231231231123");
      if (this.strcode == 500) {
        // this.$message.error("格式错误!");
        return false;
      } else {
        debugger;
        if (this.mobilePhone != null) {
          var objs = JSON.stringify({
            cno: this.cno,
            msgcode: 220,
            token: this.loginToken,
            phone: this.mobilePhone,
          });
          this.websocketsend(objs);
        } else {
          var objOne = JSON.stringify({
            cno: this.cno,
            msgcode: 220,
            token: this.loginToken,
            phone: "",
          });
          this.websocketsend(objOne);
        }
        this.isAnswer = false;
        this.hereLiS = false;
        //暂停接通时的计时器 清空定时器
        clearInterval(this.timer2);
        this.currentTime1 = 0;
        //铃不动
        clearInterval(this.timer);
        localStorage.removeItem("queryPhones");
        this.Personstatus = "空闲中";
        let obj = {
          userId: this.strUser,
          describe: this.textarea,
          type: this.type,
          typeDetail: this.checkList,
          handleTime: this.handleTime,
          priority: this.priority,
        };
        //存储本地
        localStorage.setItem("order", JSON.stringify(obj));
        console.log("===", obj);
        //暂停挂机接口
        // clearInterval(this.timer5);
        // clearInterval(this.timer7);
        //隐藏远程协助和生活服务
        this.isLive = false;
        this.isHelp = false;
        //改变客服状态
        // this.valuezhuangtai = "空闲";
        //改变客服状态下的计时器
        clearInterval(this.timer4);
        this.currentTime2 = 0;
        this.testTimer2();
        //切换上边
        this.isListion = true;
        this.isListioning = false;
        if (this.isListion == true) {
          this.iconImg = false;
        }
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.side = 1;
          ClinkAgent.unlink(params);
        });
        this.statusOne = "1";
        this.isListion = true;
        this.isListioning = false;
        this.endReason();
        this.statusOnes = "1";
        this.endReasons();
        this.statusOne = "0";
        this.statusOnes = "0";
        this.isArrangement = false;
        this.isArrangement1 = true;
        //继续请求后台是否分配来电
        // this.timer3 = setInterval(this.shout, 3000);
        //清除本地
        // localStorage.removeItem("phone");
        // localStorage.removeItem("phonex");
        // localStorage.removeItem("hhName");
      }
    },
    //有来电时铃抖动
    startRotate() {
      // clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.shrinkPacket = !this.shrinkPacket;
      }, 150);
    },
    //来电时的记时
    testTimer() {
      this.timer1 = setInterval(() => {
        this.timeFunction();
      }, 1000);
    },
    timeFunction() {
      // 开始执行倒计时
      this.timeObj = {
        // 时间对象
        seconds: Math.floor(this.currentTime % 60),
        minutes: Math.floor(this.currentTime / 60) % 60,
        hours: Math.floor(this.currentTime / 60 / 60) % 24,
      };
      // 计算出时分秒
      this.myHours =
        this.timeObj.hours < 10 ? "0" + this.timeObj.hours : this.timeObj.hours;
      this.myMinutes =
        this.timeObj.minutes < 10
          ? "0" + this.timeObj.minutes
          : this.timeObj.minutes;
      this.mySeconds =
        this.timeObj.seconds < 10
          ? "0" + this.timeObj.seconds
          : this.timeObj.seconds;
      this.currentTime++;
    },
    //通话时的记时
    testTimer1() {
      this.timer2 = setInterval(() => {
        this.timeFunction1();
      }, 1000);
    },
    timeFunction1() {
      // 开始执行倒计时
      this.timeObj1 = {
        // 时间对象
        seconds: Math.floor(this.currentTime1 % 60),
        minutes: Math.floor(this.currentTime1 / 60) % 60,
        hours: Math.floor(this.currentTime1 / 60 / 60) % 24,
      };
      // 计算出时分秒
      this.myHours1 =
        this.timeObj1.hours < 10
          ? "0" + this.timeObj1.hours
          : this.timeObj1.hours;
      this.myMinutes1 =
        this.timeObj1.minutes < 10
          ? "0" + this.timeObj1.minutes
          : this.timeObj1.minutes;
      this.mySeconds1 =
        this.timeObj1.seconds < 10
          ? "0" + this.timeObj1.seconds
          : this.timeObj1.seconds;
      this.currentTime1++;
    },
    //通话时的记时
    testTimer2() {
      this.timer4 = setInterval(() => {
        this.timeFunction2();
      }, 1000);
    },
    timeFunction2() {
      // 开始执行倒计时
      this.timeObj2 = {
        // 时间对象
        seconds: Math.floor(this.currentTime2 % 60),
        minutes: Math.floor(this.currentTime2 / 60) % 60,
        hours: Math.floor(this.currentTime2 / 60 / 60) % 24,
      };
      // 计算出时分秒
      this.myHours2 =
        this.timeObj2.hours < 10
          ? "0" + this.timeObj2.hours
          : this.timeObj2.hours;
      this.myMinutes2 =
        this.timeObj2.minutes < 10
          ? "0" + this.timeObj2.minutes
          : this.timeObj2.minutes;
      this.mySeconds2 =
        this.timeObj2.seconds < 10
          ? "0" + this.timeObj2.seconds
          : this.timeObj2.seconds;
      this.currentTime2++;
    },
    //点击录音播放事件
    broadcast() {
      this.isBroadcast = false;
      // console.log("点击了");
    },
    btn(name) {
      console.log(name, "语音");
    },
    //点击树事件
    handleNodeClick(obj) {
      this.dataTitle = obj.title;
      this.dataLists = obj.text;
      console.log(obj);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
      // return data.text.indexOf(value) !== -1
    },
    handleClick(tab, event) {
      console.log(tab, event);
      const audioElement = this.audioElement;
      if (audioElement && audioElement.length > 0) {
        for (let i = 0; i < audioElement.length; i++) {
          this.audioElement[i].pause();
        }
      }
      this.dateTime();
      //再次请求待我办理
      if (tab.label == "待我办理") {
        this.getFourCommissions();
      }
    },
    //完成的二次弹框
    quitFinish() {
      this.dialogVisibleFinish = false;
      apiShout.getUpdateHotOrder(this.quFinish).then((res) => {
        console.log("全部待办的完成：", res.data);
        if (res.data.code == 200) {
          //刷新待我办理
          this.getFourCommissions();
          //刷新历史工单
          this.getHistoryOrderUser();
          //全部待办
          this.allGetFourCommission();
          this.$message({
            message: "恭喜你，更新工单成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //全部待办的完成
    handleEdit(index, row) {
      console.log(index, row);
      this.dialogVisibleFinish = true;
      let obj = {
        status: "1",
        id: row.id,
      };
      this.quFinish = { ...obj };
      // apiShout.getUpdateHotOrder(obj).then((res) => {
      //   console.log("全部待办的完成：", res.data);
      //   if (res.data.code == 200) {
      //     //刷新待我办理
      //     this.getFourCommissions();
      //     //刷新历史工单
      //     this.getHistoryOrderUser();
      //     //全部待办
      //     this.allGetFourCommission();
      //     this.$message({
      //       message: "恭喜你，更新工单成功",
      //       type: "success",
      //     });
      //   }
      // });
    },
    handleClickRight(tab, event) {
      console.log(tab, event);
    },
    changeIndex(val) {
      this.num = val;
    },
    // 将秒转为00:00:00格式
    format(seconds) {
      let hour =
        Math.floor(seconds / 3600) >= 10
          ? Math.floor(seconds / 3600)
          : "0" + Math.floor(seconds / 3600);
      seconds -= 3600 * hour;
      let min =
        Math.floor(seconds / 60) >= 10
          ? Math.floor(seconds / 60)
          : "0" + Math.floor(seconds / 60);
      seconds -= 60 * min;
      let sec = seconds >= 10 ? seconds : "0" + seconds;
      return hour + ":" + min + ":" + sec;
    },
    Callbacks(val) {
      console.log(val);
      if (val == true) {
        this.showPhone = true;
        this.showPhones = false;
        this.workOrder.callBackPhone = this.localNumber;
        console.log(
          this.workOrder.callBackPhoneOne,
          this.mobilePhone,
          "本机回电"
        );
        this.workOrder.callBackPhoneOne = this.workOrder.callBackPhone;
        if (this.workOrder.callBackPhone != undefined) {
          let str = this.workOrder.callBackPhone;
          this.workOrder.callBackPhoneOne = str.replace(
            /(\d{3})\d{4}(\d{1})/,
            "$1****$2"
          );
        }
      } else {
        this.showPhones = true;
        this.showPhone = false;
      }
    },
    callBackOne(val) {
      this.$forceUpdate();
      this.workOrder.callBackPhoneOne = val;
    },
    callBackPhones(val) {
      this.callback1 = val;
      if (val == "1") {
        this.showPhone = true;
        this.showPhones = false;
        this.workOrder.callBackPhoneOne = this.mobilePhone;
        this.workOrder.callBackPhone = this.mobilePhone;
        console.log(this.workOrder.callBackPhoneOne, "点击第二个回电的号码！");
        console.log(this.workOrder.callBackPhone, "点击需要回电查看号码！");
        if (this.workOrder.callBackPhone != undefined) {
          this.workOrder.callBackPhoneOne = this.workOrder.callBackPhone;
          let str = this.workOrder.callBackPhone;
          this.workOrder.callBackPhoneOne = str.replace(
            /(\d{3})\d{4}(\d{1})/,
            "$1****$2"
          );
        }
      }
      this.historyOrdertime.forEach((item) => {
        if (item.value === "6") {
          return (this.workOrder.handleTime = item.value);
        }
      });
      this.historyOrderputong.forEach((item) => {
        if (item.value === "3") {
          return (this.workOrder.priority = item.value);
        }
      });
      if (this.callback1 == "0") {
        this.CallbackInformation = false;
      } else if (this.callback1 == "1") {
        this.CallbackInformation = true;
      }
    },
    //请求挂断的状态的接口
    endReason() {
      console.log(this.textPhone, this.phoneOne, "1231231231231231123");
      // let phoneName = JSON.parse(localStorage.getItem("phonex"));
      // console.log(phoneName, "挂断取消");
      // clearInterval(this.timer3);
      // this.valuezhuangtai = "空闲";
      this.isListion = true;
      this.isListioning = false;
      this.iconImg = false;
      if (this.isListion == true) {
        this.isAnswer = false;
        this.$refs.audioLing.pause();
      }
      this.Personstatus = "空闲中";
      // clearInterval(this.timer5);
      // clearInterval(this.timer7);
      //客服状态的计时
      clearInterval(this.timer4);
      this.currentTime2 = 0;
      this.testTimer2();
      //清除本地
      localStorage.removeItem("phone");
      // localStorage.removeItem("phoneNumber");
      // localStorage.removeItem("phonex");
      //继续请求
      // this.timer3 = setInterval(this.shout, 3000);
    },
    endReasons() {
      console.log(this.textPhone, this.phoneOne, "1231231231231231123");
      // clearInterval(this.shout);
      // let phoneHH = JSON.parse(localStorage.getItem("hhName"));
      console.log(this.mobilePhone, "挂断2222");
      // clearInterval(this.timer3);
      // this.valuezhuangtai = "空闲";
      this.isListion = true;
      this.isListioning = false;
      this.iconImg = false;
      if (this.isListion == true) {
        this.isAnswer = false;
        this.$refs.audioLing.pause();
      }
      this.Personstatus = "空闲中";
      // clearInterval(this.timer5);
      // clearInterval(this.timer7);
      //客服状态的计时
      clearInterval(this.timer4);
      this.currentTime2 = 0;
      this.testTimer2();
      //继续请求
      // this.timer3 = setInterval(this.shout, 3000);
      //清除本地
      localStorage.removeItem("phone");
      // localStorage.removeItem("phoneNumber");
      // localStorage.removeItem("phonex");
      // localStorage.removeItem("hhName");
    },
    handleCloseFinish() {
      this.dialogVisibleFinish = false;
    },
    //点击表格的每一行事件
    rowClick(row, column, event) {
      this.isactive = false;
      // let phoneName = JSON.parse(localStorage.getItem("phonex"));
      // let hhName = JSON.parse(localStorage.getItem("hhName"));
      console.log(this.mobilePhone, "123123输出");
      this.xianName = row.name;
      if (this.textPhone == null && this.mobilePhone == null) {
        console.log("点击表格每一行：", row, column, event);
        this.mobilePhone = row.callBackPhone;
        this.localNumber = row.phone;
        this.queryPhone = row.phone;
        this.workOrder.newPhone = row.newPhone;
        this.workOrder.callBackPhone = row.callBackPhone;
        localStorage.setItem("UniqueIdnFour", JSON.stringify(row.mainUniqueId));
        if (row.callBackPhone == "" || row.callBackPhone == null) {
          this.workOrder.callBackPhone = row.phone;
          this.workOrder.callBackPhoneOne = row.phone;
        } else if (row.callBackPhone != "" || row.callBackPhone != null) {
          this.workOrder.callBackPhone = row.callBackPhone;
          this.workOrder.callBackPhoneOne = row.callBackPhone;
        }
        let str = this.workOrder.callBackPhone;
        this.workOrder.callBackPhoneOne = str.replace(
          /(\d{3})\d{4}(\d{1})/,
          "$1****$2"
        );
        // this.workOrder.callBackPhone =
        //   this.workOrder.callBackPhone.substr(0, 3) +
        //   "****" +
        //   this.workOrder.callBackPhone.substr(7);
        if (
          this.workOrder.callBackPhoneOne != null ||
          this.mobilePhone != null
        ) {
          this.imgIcon = true;
          this.imgIcons = false;
        } else {
          this.imgIcons = true;
          this.imgIcon = false;
        }
        this.getHistoryOrderUser();
        apiShout
          .getOuterMemberPhones({ phone: this.queryPhone })
          .then((res) => {
            console.log(res, "来电显示用户信息");
            this.dataResult = res.data.result;
            if (res.data.code === 200 && res.data.result != null) {
              // this.mobilePhone = res.data.result.phone;
              this.workOrder.customerName = res.data.result.name;
              this.cityNames = res.data.result.city;
              this.provinceName = res.data.result.province;
              this.workOrder.phone = res.data.result.phone;
              let str = this.workOrder.phone;
              this.workOrder.fixedPhone = str.replace(
                /(\d{3})\d{4}(\d{1})/,
                "$1****$2"
              );
              // this.workOrder.phone =
              //   this.workOrder.phone.substr(0, 3) +
              //   "****" +
              //   this.workOrder.phone.substr(7);
              this.workOrder.sex = res.data.result.sex;
              this.workOrder.city = CodeToText[this.cityNames];
              this.workOrder.province = CodeToText[this.provinceName];
              this.countysId = res.data.result.province;
              this.citysId = res.data.result.city;
            } else {
              this.workOrder.customerName = "";
              (this.workOrder.province = ""),
                (this.workOrder.city = ""),
                (this.workOrder.callBackPhoneOne = ""),
                (this.mobilePhone = "");
              this.workOrder.fixedPhone = "";
              this.workOrder.sex = "";
              this.$message.error(res.data.msg);
            }
          });
      } else if (
        this.textPhone == null &&
        this.isListion == true &&
        this.mobilePhone != null
      ) {
        console.log("点击表格每一行2：", row, column, event);
        this.workOrder.fixedPhone = row.phone;
        this.mobilePhone = row.callBackPhone;
        this.queryPhone = row.phone;
        this.workOrder.callBackPhone = row.callBackPhone;
        localStorage.setItem("UniqueIdnFour", JSON.stringify(row.mainUniqueId));
        if (row.callBackPhone == "" || row.callBackPhone == null) {
          this.workOrder.callBackPhone = row.phone;
          this.workOrder.callBackPhoneOne = row.phone;
        } else if (row.callBackPhone != "" || row.callBackPhone != null) {
          this.workOrder.callBackPhone = row.callBackPhone;
          this.workOrder.callBackPhoneOne = row.callBackPhone;
        }
        let str = this.workOrder.callBackPhone;
        this.workOrder.callBackPhoneOne = str.replace(
          /(\d{3})\d{4}(\d{1})/,
          "$1****$2"
        );
        this.workOrder.newPhone = row.newPhone;
        // this.workOrder.callBackPhone =
        //   this.workOrder.callBackPhone.substr(0, 3) +
        //   "****" +
        //   this.workOrder.callBackPhone.substr(7);
        if (this.workOrder.callBackPhoneOne != null) {
          this.imgIcon = true;
          this.imgIcons = false;
        } else {
          this.imgIcons = true;
          this.imgIcon = false;
        }
        this.getHistoryOrderUser();
        apiShout
          .getOuterMemberPhones({ phone: this.queryPhone })
          .then((res) => {
            console.log(res, "来电显示用户信息");
            this.dataResult = res.data.result;
            if (res.data.code === 200 && res.data.result != null) {
              this.workOrder.customerName = res.data.result.name;
              this.cityNames = res.data.result.city;
              this.provinceName = res.data.result.province;
              // this.mobilePhone = res.data.result.phone;
              this.workOrder.phone = res.data.result.phone;
              let str = this.workOrder.phone;
              this.workOrder.fixedPhone = str.replace(
                /(\d{3})\d{4}(\d{1})/,
                "$1****$2"
              );
              // this.workOrder.phone =
              //   this.workOrder.phone.substr(0, 3) +
              //   "****" +
              //   this.workOrder.phone.substr(7);
              this.workOrder.sex = res.data.result.sex;
              this.workOrder.city = CodeToText[this.cityNames];
              this.workOrder.province = CodeToText[this.provinceName];
              this.countysId = res.data.result.province;
              this.citysId = res.data.result.city;
            } else {
              this.workOrder.customerName = "";
              (this.workOrder.province = ""),
                (this.workOrder.city = ""),
                (this.workOrder.callBackPhoneOne = ""),
                (this.mobilePhone = "");
              this.workOrder.fixedPhone = "";
              this.workOrder.sex = "";
              this.$message.error(res.data.msg);
            }
          });
      } else if (this.textPhone != null && this.mobilePhone != null) {
        event.isTrusted = false;
      }
    },
    //常用知识
    offenKnow() {
      //获取常用知识
      apiShout.getKnowledge().then((res) => {
        console.log("常用知识：", res.data);
        if (res.data.code === 200) {
          this.dataList = res.data.result;
          this.dataLists = res.data.result;
          if (this.dataLists.length > 0) {
            for (var i = 0; i < this.dataLists.length; i++) {
              for (var j = 0; j < this.dataLists[i].childs.length; j++) {
                this.dataLists = this.dataLists[i].childs[0].text;
                this.dataTitle = this.dataLists[i].childs[0].title;
              }
            }
          } else {
            this.dataLists = "";
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  beforeDestroy() {
    // this.close();
    // localStorage.clear("cno");
    // localStorage.clear("queryPhones");
    clearInterval(this.timer);
    clearInterval(this.timer1);
    clearInterval(this.timer2);
    clearInterval(this.timer4);
    clearInterval(this.tokenM);
  },
};
</script>

<style lang='less' scoped>
//搜索弹出框高度限制
.el-table {
  font-size: 14px;
  color: #606266;
  max-height: 730px;
  overflow: auto;
}
.addclass {
  background: #ebeef5;
}
//通话记录里的日期的样式
/deep/ .tonghua .el-input__inner {
  width: 65%;
  color: rgb(185, 182, 182);
}
/deep/ .tonghua .el-icon-date:before {
  content: none;
}
/deep/ .tonghua .el-date-editor {
  text-align: right;
}
/deep/ .tonghua .icon-triangle-right:before {
  position: absolute;
  left: 290px;
  top: 2px;
}
/deep/ .tonghua .icon-triangle-left:before {
  position: absolute;
  left: 150px;
  top: 2px;
}
//标记
.badge {
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 24px;
  position: absolute;
  left: 195px;
  // top: 115px;
  top: -19px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
  font-size: 19px;
  padding: 3px 3px;
}
.badges {
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 195px;
  // top: 115px;
  top: -19px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
  font-size: 19px;
}
.badge1 {
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 423px;
  // top: 115px;
  top: -19px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
}
.badge1s {
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 423px;
  // top: 115px;
  top: -19px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
}
// 最上边
.center-top {
  position: relative;
  // margin: 10px 10px;
  height: 80px;
  line-height: 50px;
}
.top-name {
  font-size: 30px;
  color: #92aca7;
  margin-left: 40px;
  float: left;
}
.lisitions {
  width: 60px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background: #76b935;
  color: #fff;
  border-radius: 20px;
  float: left;
  margin: 15px 10px 10px 3px;
  font-size: 14px;
}
.top-tome {
  float: left;
}
.top-tu {
  display: inline-block;
  margin-left: -16px;
  margin-top: -15px;
}
.top-tu-one {
  float: left;
  margin-top: 15px;
}
.top-tu-one:nth-child(2) {
  margin-left: 10px;
}
.top-tu-one:last-child {
  margin-left: 13px;
}
.top-tu:first-child {
  margin-left: 50px;
}
.top-rig {
  float: right;
}
.top-right {
  // position: absolute;
  // top: 15px;
  // left: 1100px;
  float: left;
  .r1 {
    transform: rotate(-5deg);
  }
  .r2 {
    transform: rotate(3deg);
  }
}
.top-time {
  // position: absolute;
  // top: 0px;
  // left: 1150px;
  float: left;
  font-size: 17px;
  color: rgb(197, 193, 193);
}
.top-jie {
  // position: absolute;
  // top: 15px;
  // left: 1200px;
  float: left;
}
.top-zhuangtai {
  // position: absolute;
  // top: 0px;
  // left: 1410px;
  float: left;
  font-size: 17px;
  color: rgb(197, 193, 193);
}
.top-zhuangtai1 {
  float: right;
  font-size: 17px;
  color: rgb(197, 193, 193);
}
.my-zhuangtai {
  margin-right: 20px;
}
.top-tonghua {
  position: absolute;
  top: 30px;
  right: 30px;
}
//下左边
.bottom {
  font-size: 19px;
  color: #d78673;
  margin: 10px 10px 10px 0px;
  display: flex;
  overflow-x: auto;
  padding: 20px 0px 0px 0px;
}
.bottom-left {
  // width: 60%;
  flex: 1;
  margin-right: 10px;
  position: relative;
}
.bottom-right {
  // width: 40%;
  height: 1000px;
  flex: 1;
}
.home .el-aside {
  background: #fff;
}
/deep/ .el-tabs__item {
  color: #d78673;
  font-size: 18px;
}
/deep/ .el-tabs__item.is-active {
  color: #fff;
  background-color: #d78673;
}
/deep/ .el-tree-node__label.aside-tree {
  color: #fff;
  background-color: #d78673;
}
/deep/ .el-input__inner {
  height: 30px;
  margin-bottom: 6px;
  margin-left: 0px;
}
/deep/ .el-tree-node__label {
  color: #92aca7;
  font-size: 18px;
}
.nav > div[data-v-232c23e3] {
  padding: 15px;
  border: 1px solid #eee;
}
.active[data-v-01042540] {
  color: #af6e5f;
  background: #d78673;
}
// tabs下边的边框
/deep/ .el-tabs__content {
  border: 1px solid #eee;
  height: 730px;
}
.search {
  display: flex;
  width: 300px;
  font-size: 16px;
  .imgs {
    width: 53px;
    height: 31px;
    margin-left: 10px;
  }
}
.left {
  display: flex;
  margin: 10px 0;
  font-size: 16px;
  color: #a8a8a8;
  .el-tabs--left .el-tabs__item.is-left {
    text-align: center;
  }
}
/deep/ .el-tree-node__label.aside-tree {
  color: #fff;
  background-color: #d78673;
}
.tonghua {
  text-align: center;
  color: #c0bdbd;
  height: 35px;
  line-height: 30px;
}
.right {
  padding: 15px;
  line-height: 23px;
  white-space: pre-wrap;
}
/deep/ .el-table th.el-table__cell > .cell {
  background-color: #92aca7;
  color: #fff;
  font-size: 17px;
  padding: 10px;
}
/deep/ .el-table tr:nth-child(odd) {
  background-color: #e5f4f1;
}
/deep/ .el-table tr:nth-child(even) {
  background-color: #fff;
}
.luyin {
  color: #d78673;
}
// 下右边
//历史工单
.history {
  width: 95%;
  border: 1px solid #f0eeee;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  margin: 10px;
  padding: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.historydan {
  color: #92aca7;
  font-size: 19px;
  font-weight: bolder;
}
.history-ul {
  height: 300px;
  //background-color: #d78673;
  overflow: auto;
}
.suggest {
  width: 80px;
  height: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #92aca7;
  color: #fff;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  margin: 10px;
}
.person {
  color: #92aca7;
  font-size: 16px;
  margin: 10px 5px 10px 20px;
}
.person-id {
  color: #92aca7;
  font-size: 16px;
  margin: 10px 5px 10px 20px;
}
.times {
  color: rgb(192, 188, 188);
  font-size: 14px;
}
.content {
  color: rgb(192, 188, 188);
  font-size: 14px;
  margin: 0px 0px 10px 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d6d4d4;
}
//历史工单的 创建工单
.historydan1 {
  color: #92aca7;
  font-size: 19px;
  font-weight: bolder;
  display: inline-block;
}
.create-order {
  width: 95%;
  border: 1px solid #f0eeee;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  margin: 10px;
  padding: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.infor-content {
  display: flex;
}
.inforwrite {
  width: 325px;
  height: 200px;
  border: 1px solid #d6d4d4;
}
.history-phone {
  margin-left: 20px;
  color: rgb(221, 216, 216);
  font-size: 16px;
}
.fenglei {
  color: #92aca7;
  font-size: 15px;
  margin-left: 20px;
}
.radio-dan {
  margin: 10px 10px 10px 20px;
}
.his-right {
  margin: 0px 10px 10px 10px;
}
.infor-bottom {
  margin-top: 10px;
}
.benji-phone {
  margin-left: 10px;
  color: rgb(199, 196, 196);
  font-size: 16px;
}
.buttons {
  width: 60px;
  height: 30px;
  line-height: 20px;
  text-align: center;
  padding: 5px;
  border-radius: 25px;
  background-color: #d78673;
  color: #ffffff;
  font-size: 14px;
  display: inline-block;
  margin: 10px;
}
//信息查询
.right-big {
  margin: 10px;
  border: 1px solid #f0eeee;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  padding: 10px;
  width: 600px;
  float: left;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.infor-top {
  height: 30px;
}
.baseinfor {
  font-size: 14px;
  color: #92aca7;
  margin-top: 5px;
  margin-right: 100px;
  float: left;
}
.base-niceng {
  float: left;
}
.base-imgs {
  float: left;
}
.base-images {
  float: left;
  width: 50px;
  height: 28px;
  margin-left: 30px;
}
.base-content {
  height: 300px;
}
//调小距离
[class*="el-col-"] {
  height: 40px;
}
.faimal {
  margin-right: 5px;
}
.inforbirth {
  position: absolute;
  left: 22px;
  top: 182px;
}
.dengji {
  position: absolute;
  left: 22px;
  top: 230px;
}
.inforInput {
  position: absolute;
  left: 100px;
  top: 270px;
  width: 269px;
  border-color: #eee;
}
.shiji {
  position: absolute;
  left: 20px;
  top: 310px;
}
.shijibox {
  //border: 1px solid #ebeaea;
  font-size: 16px;
  color: rgb(185, 179, 179);
  margin-left: 10px;
}
.jihuo {
  position: absolute;
  left: 400px;
  top: 240px;
}
.daoqi {
  position: absolute;
  left: 400px;
  top: 280px;
}
/deep/ .el-form--inline .el-form-item__label {
  font-size: 14px;
  font-weight: bolder;
  color: #a8a8a8;
}
//生活状况
.life-status [class*="el-col-"] {
  float: left;
}
.life-zhuangtai {
  color: #92aca7;
  font-size: 14px;
}
.big-center {
  display: flex;
}
.left,
.life-status,
.jiangkang {
  background: #ffffff;
  border-radius: 15px;
  // margin: 0 10px;
  flex: 1;
  table {
    width: 100%;
    margin-top: 20px;
    tr {
      // margin-bottom: 17px;
      display: flex;
      td:first-child {
        color: #a8a8a8;
        font-weight: bolder;
        font-size: 14px;
        width: 80px;
      }
      td:nth-child(2) {
        font-size: 14px;
        color: #a8a8a8;
        padding-right: 15px;
        flex: 1;
      }
      td {
        .tdspan {
          line-height: 35px;
        }
        .el-select {
          width: 100%;
          .el-input__inner {
            border: 1px solid #ffffff;
            color: #c8c8c8;
            font-size: 16px;
          }
        }
      }
      .infor-img {
        margin-left: 110px;
      }
    }
  }
}
// 健康数据
.jiangkang {
  float: left;
  margin-left: 20px;
}
//保存
.baocun {
  position: absolute;
  left: 500px;
  bottom: 20px;
}
//tabs右浮
/deep/ .bottom-right .el-tabs__nav-scroll {
  float: right;
}
//表格里的内容居中对齐
/deep/ .el-table td.el-table__cell div {
  text-align: center;
}
/deep/ .el-table .cell {
  text-align: center;
}
//接听时的计时器样式
.timer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 0em;
  color: #c2bebe;
}
.time-card {
  margin: 0 5px;
  text-align: center;
}
.time-card-count {
  font-size: 14px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  overflow: hidden;
}
.time-card-label {
  font-size: 0.625em;
  text-transform: uppercase;
  opacity: 0.7;
}
audio {
  width: 100%;
  height: 40px;
  outline: none;
  filter: none;
  border-radius: 30px;
}

audio::-webkit-media-controls-enclosure {
  background: none;
  border-radius: 4px;
}
.media-controls-container,
.media-controls-container * {
  background: none;
  border-radius: 4px;
}
audio::-webkit-media-controls-play-button {
  height: 22px;
  width: 22px;
  min-width: 22px;
  border-radius: 50%;
  flex-basis: 22px;
}
audio::-webkit-media-controls-volume-control-container {
  display: none;
}

audio::-webkit-media-controls-current-time-display {
  order: 1;
  color: #000;
  text-shadow: unset;
}

audio::-webkit-media-controls-time-remaining-display {
  order: 2;
  color: rgba(0, 0, 0, 0.6);
  text-shadow: unset;
}
/deep/ .el-table .sort-caret.ascending {
  top: -13px;
}
/deep/ .el-table .sort-caret.descending {
  bottom: -10px;
}
/deep/ .el-table .caret-wrapper {
  height: auto;
}
.fourCall {
  font-size: 20px;
  margin-right: 10px;
  color: #d78673;
}
//本页面的tab样式
/deep/ .el-tabs__item {
  border: 1px solid #e4e7ed !important;
  border-left: 1px solid #e4e7ed !important;
  outline: none;
}
/deep/.el-tabs__item.is-active {
  color: #fff !important;
  border: none;
  font-size: 18px !important;
}
/deep/.el-tabs__item:hover {
  color: #d78673 !important;
  font-size: 18px !important;
}
.tellLink /deep/.el-dialog {
  margin-top: 25vh !important;
}
.tellLink /deep/.el-dialog__body {
  margin: 17px 23px 0 23px;
}
</style>